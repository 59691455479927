import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearStoredVars } from '../../utils/jwt-helpers';
import { reloadFromCookie, isAuthenticated } from '../../lib/userSlice';

export const useAuthCookie = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const reduxAuth = useSelector(isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDevelopment) {
      // TODO: Figure out how to clear this better; this is happening on every reload...
      // clearStoredVars();
    }
    dispatch(reloadFromCookie());
  }, [reduxAuth]);

  return reduxAuth;
};
