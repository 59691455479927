import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import HeaderWithSidebar from './HeaderWithSidebar';
import ProgressSidenav from './ProgressSidenav';
import ControlList from './ControlList';

import {
  useGetSystemQuery,
  useGetControlsQuery,
} from '../../lib/ascertis-api';

import { buildControlStructure } from '../../lib/routing/control-list-methods';

const classNames = require('classnames');

const percentImplementationCompleteCalculator = (collection) => {
  const totalControls = _.size(collection);
  if (totalControls === 0) return 0;
  const answeredImplementations = _.filter(collection, (item) => {
    return item.implementationSelected;
  }).length;
  return answeredImplementations / totalControls;
};

const ImplementationLayout = ({ children }) => {
  const { systemId, controlId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: controls } = useGetControlsQuery({ systemId });

  const structure = buildControlStructure(system, controls, controlId, 'implementation');
  const completionPercentage = percentImplementationCompleteCalculator(controls);

  const wrapperClasses = classNames({
    'app-container': true,
    'with-sidebar': true
  });

  return (
    <div className={wrapperClasses}>
      <HeaderWithSidebar>
        <ProgressSidenav percentage={completionPercentage}>
          <ControlList structure={structure} />
        </ProgressSidenav>
      </HeaderWithSidebar>
      <main>
        {children}
      </main>
    </div>
  );
};

ImplementationLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ImplementationLayout.defaultProps = {
  children: null
};

export default ImplementationLayout;
