import _ from 'lodash';
import * as Yup from 'yup';

const columnList = {
  "itemId": "Item ID",
  "domain": "Domain",
  "controlNumber": "Control Number",
  "controlCategory": "Control Category",
  "controlType": "Control Type",
  "control": "Control",
  "requirement": "Requirement",
  "status": "Status",
  "remediation": "Remediation",
  "resourcesNeeded": "Resources Needed",
  "projectedCompletion": "Projected Completion",
  "actualCompletion": "Actual Completion",
  "implementationNotes": "Implementation Notes",
  "requirementNotes": "Requirement Notes"
};

const actionItemsDictionary = {
  "Item ID": "itemId",
  "Domain": "domain",
  "Control Number": "controlNumber",
  "Control No": "controlNumber",
  "Control Num": "controlNumber",
  "Control Category": "controlCategory",
  "Control Cat": "controlCategory",
  "Control Type": "controlType",
  "Control": "control",
  "Requirement": "requirement",
  "Status": "status",
  "Remediation": "remediation",
  "Resources Needed": "resourcesNeeded",
  "Projected Completion": "projectedCompletion",
  "Actual Completion": "actualCompletion",
  "Implementation Notes": "implementationNotes",
  "Requirement Notes": "requirementNotes"
};

const dateFields = [
  'projectedCompletion',
  'actualCompletion'
];

function cleanColumnString(string) {
  // look up how to best transform this into a key
  // const lowercase = string.toLowerCase();
  const withoutExtraWhitespace = string.replace(/\s+/g, " ").replace(/^\s|\s$/g, "");
  const onlyAlphanumeric = withoutExtraWhitespace.replace(/[^a-zA-Z]/g, '');
  return onlyAlphanumeric;
}

function stringMatchesColumnName(value) {
  const formattedValue = cleanColumnString(value).toLowerCase();
  const registry = validColumnNames();
  const formattedRegistry = _.map(registry, (val) => {
    return cleanColumnString(val).toLowerCase();
  });
  return _.includes(formattedRegistry, formattedValue);
}

function getFormattedColumnName(string) {
  let columnName;
  const dict = actionItemsDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnName = key;
    }
  });
  return columnName;
}

function getColumnKey(string) {
  let columnKey;
  const dict = actionItemsDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnKey = dict[key];
    }
  });
  return columnKey;
}

export function validColumnNames() {
  return Object.keys(actionItemsDictionary);
}

export function getValidIndexes(excelJson) {
  const columnRow = excelJson[0];
  const validIndexes = [];
  columnRow.forEach((value, i) => {
    if (stringMatchesColumnName(value)) {
      validIndexes.push(i);
    }
  });
  return validIndexes;
}

export function actionItemsToExcel(listData) {
  return _.map(listData, (row) => {
    return {
      ...row
    };
  });
}

export function getActionItemData(excelJson) {
  const noEmptyRows = _.filter(excelJson, function(row) {
    return row.length;
  });
  const validIndexes = getValidIndexes(noEmptyRows);
  const outputData = [];
  noEmptyRows.forEach((row) => {
    const newRow = [];
    validIndexes.forEach((index) => {
      newRow.push(row[index]);
    });
    outputData.push(newRow);
  });
  return outputData;
}

export function allColumns() {
  return _.map(columnList, (val, key) => {
    const type = _.includes(dateFields, key) ? 'date' : 'text';
    return {
      name: val,
      key,
      type
    };
  });
}

export function actionItemDataColumns(excelJson) {
  const validData = getActionItemData(excelJson);
  const headers = validData[0];
  return _.map(headers, (header) => {
    const key = getColumnKey(header);
    const name = getFormattedColumnName(header);
    const type = _.includes(dateFields, key) ? 'date' : 'text';
    return {
      name,
      key,
      type
    };
  });
}

export function actionItemDataRows(excelJson) {
  const columns = actionItemDataColumns(excelJson);
  let validData = getActionItemData(excelJson);
  validData.shift();
  const formattedData = [];
  _.forEach(validData, (row) => {
    const newRow = {};
    _.forEach((columns), (column, index) => {
      newRow[`${column.key}`] = row[index];
    });
    formattedData.push(newRow);
  });
  return formattedData;
}
