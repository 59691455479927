import _ from 'lodash';
// import * as equipmentListDictionary from './equipment_list_key_dictionary.json';

const columnList = {
  "name": "Name",
  "vendor": "Vendor",
  "version": "Version",
  "patchLevel": "Patch Level"
};

const softwareListDictionary = {
  "Name": "name",
  "Vendor": "vendor",
  "Vend": "vendor",
  "Version": "version",
  "Vers": "version",
  "Ver": "version",
  "Patch": "patchLevel",
  "Patch Lvl": "patchLevel",
  "Patch Level": "patchLevel"
};

function cleanColumnString(string) {
  // look up how to best transform this into a key
  // const lowercase = string.toLowerCase();
  const withoutExtraWhitespace = string.replace(/\s+/g, " ").replace(/^\s|\s$/g, "");
  const onlyAlphanumeric = withoutExtraWhitespace.replace(/[^a-zA-Z]/g, '');
  return onlyAlphanumeric;
}

function stringMatchesColumnName(value) {
  const formattedValue = cleanColumnString(value).toLowerCase();
  const registry = validColumnNames();
  const formattedRegistry = _.map(registry, (val) => {
    return cleanColumnString(val).toLowerCase();
  });
  return _.includes(formattedRegistry, formattedValue);
}

function getFormattedColumnName(string) {
  let columnName;
  const dict = softwareListDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnName = key;
    }
  });
  return columnName;
}

function getColumnKey(string) {
  let columnKey;
  const dict = softwareListDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnKey = dict[key];
    }
  });
  return columnKey;
}

export function validColumnNames() {
  const dict = require('./software_list_key_dictionary.json');
  return Object.keys(dict);
}

export function getValidIndexes(excelJson) {
  const columnRow = excelJson[0];
  const validIndexes = [];
  columnRow.forEach((value, i) => {
    if (stringMatchesColumnName(value)) {
      validIndexes.push(i);
    }
  });
  return validIndexes;
}

export function softwareDataToExcel(listData) {
  return _.map(listData, (row) => {
    return {
      ...row
    };
  });
}

export function getSoftwareData(excelJson) {
  // this should also the keys/blanks for columns that aren't in the data

  const noEmptyRows = _.filter(excelJson, function(row) {
    return row.length;
  });
  const validIndexes = getValidIndexes(noEmptyRows);
  const outputData = [];
  noEmptyRows.forEach((row) => {
    const newRow = [];
    validIndexes.forEach((index) => {
      newRow.push(row[index]);
    });

    outputData.push(newRow);
  });

  return outputData;
}

export function allColumns() {
  return _.map(columnList, (val, key) => {
    return { name: val, key: key };
  });
}

export function softwareDataColumns(excelJson) {
  const validData = getSoftwareData(excelJson);
  const headers = validData[0];
  return _.map(headers, (header) => {
    return { name: getFormattedColumnName(header), key: getColumnKey(header) };
  });
}

export function softwareDataRows(excelJson) {
  const columns = softwareDataColumns(excelJson);
  let validData = getSoftwareData(excelJson);
  validData.shift();
  const formattedData = [];
  _.forEach(validData, (row) => {
    const newRow = {};
    _.forEach((columns), (column, index) => {
      newRow[`${column.key}`] = row[index];
    });
    formattedData.push(newRow);
  });
  return formattedData;
}
