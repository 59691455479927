import React from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const classNames = require('classnames');

export class SideNav extends React.Component {
  componentDidMount() {
    // SideNav functionality (Materialize)
    const options = {
      menuWidth: this.props.width, // Default is 300
      edge: 'left', // Choose the horizontal origin
      draggable: true // Choose whether you can drag to open on touch screens,
    };
    const elems = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems, options);
  }

  render() {
    const { id, className } = this.props;

    const sideNavClasses = classNames({
      'sidenav': true,
      'sidenav-fixed': this.props.fixed,
      [`${className}`]: className
    });

    return (
      <ul id={id} className={sideNavClasses} >
        {this.props.children}
      </ul>
    );
  }
}

SideNav.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  fixed: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

SideNav.defaultProps = {
  width: 300,
  fixed: false,
  className: '',
  children: null
};

export class SideNavButton extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    const { sideNavId, className } = this.props;

    const buttonClasses = classNames({
      'button-collapse': true,
      'hide-on-large-only': true,
      'top-nav': true,
      'sidenav-trigger': true,
      'full': true,
      [`${className}`]: className
    });

    const content = <i className="material-icons">menu</i>;

    return (
      <a href=""
         role="button"
         tabIndex="0"
         data-target={sideNavId}
         className={buttonClasses}
         onClick={this.handleClick}
      >
        { content }
      </a>
    );
  }
}

SideNavButton.propTypes = {
  sideNavId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

SideNavButton.defaultProps = {
  className: '',
  onClick: () => {}
};
