import React from 'react';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useUpdateControlNoteMutation } from '../../lib/ascertis-api';

const EditNoteForm = ({ system, control, note, onSubmit }) => {
  const [updateNote] = useUpdateControlNoteMutation();

  const handleSubmit = async (formValues) => {
    await updateNote({ systemId: system.id, controlId: control.id, noteId: note.id, params: formValues }).unwrap()
      .then((payload) => {
        onSubmit()
      }).catch((error) => {
      });
  };

  const Schema = Yup.object().shape({
    body: Yup.string().required()
  });

  return (
    <div id="edit-note-form">
      <Formik
        initialValues={{ body: note.body }}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          return handleSubmit(values, actions);
        }}
      >
        {props => (
          <Form role="form">
            <Row>
              <Column small={12}>
                <Field
                  id="note-body"
                  name="body"
                  prompt="Note"
                  component={TextAreaInput}
                />
              </Column>
            </Row>

            <Row className="form-button-container right-align">
              <SubmitButton>Save</SubmitButton>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditNoteForm;
