import _ from 'lodash';
const { getJsDateFromExcel } = require('excel-date-to-js');
const moment = require('moment');

export const yesNoInterpreter = (value) => {
  const truthy = [true, 'TRUE', 'True', 'true', 1, '1', 'YES', 'Yes', 'yes'];
  if (truthy.includes(value)) {
    return 'Yes';
  } else {
    return 'No';
  }
};

export const convertDateFields = (jsonDataArray, dateFields = []) => {
  const headerData = jsonDataArray[0];
  let rowData = jsonDataArray.slice(1);
  _.forEach(dateFields, (field) => {
    const index = _.indexOf(headerData, field);
    _.forEach(rowData, (row, rowIndex) => {
      let rowCopy = row;
      rowCopy[index] = convertToDateString(row[index]);
      rowData[rowIndex] = rowCopy;
    });
  });
  // return rowData;
  return [headerData, ...rowData];
};

const convertToDateString = (value) => {
  if (_.isNumber(value)) {
    const roundedValue = _.round(value);
    const date = getJsDateFromExcel(roundedValue);
    return moment.utc(date).format('YYYY-MM-DD');
  } else {
    return value;
  }
};
