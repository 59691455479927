import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataGrid, { textEditor } from 'react-data-grid';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS } from 'date-fns/locale/en-US';
import _ from 'lodash';
const { getJsDateFromExcel } = require('excel-date-to-js');
const moment = require('moment');

registerLocale('enUS', enUS);

const dateEditor = ({ column, row, onRowChange, onClose }) => {
  const dateFormat = 'YYYY-MM-DD';
  return (
    <DatePicker
      dateFormat={dateFormat}
      locale="enUS"
      open
      onChange={(date) => {
        onRowChange(
          {
            ...row,
            [column.key]: moment(date).format('YYYY-MM-DD')
          },
          true
        );
      }}
      id="dateEditor"
      className="dateEditor"
      showMonthDropdown
      showYearDropdown
      onCalendarClose={() => {
        onClose(true);
      }}
    />
  );
};

const EmptyRowsView = () => {
  const message = 'No data to show';
  return (
    <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }} >
      <h3>{message}</h3>
    </div>
  );
};

const formatRows = (rowsData) => {
  let formattedRows = rowsData;
  _.forEach(formattedRows, (value, index) => {
    value.id = index + 1;
  });
  return formattedRows;
};

const buildDataColumns = (columns) => {
  // https://adazzle.github.io/react-data-grid/docs/examples/column-filtering
  let newColumns = [];
  newColumns.push({
    key: 'id',
    name: 'Row',
    width: 150,
    resizable: true,
    editable: false
  });
  _.forEach(columns, (column) => {
    if (column.type === 'date') {
      newColumns.push({
        key: column.key,
        name: column.name,
        width: 150,
        resizable: true,
        renderCell: ({ column, row }) => {
          const value = row[column.key];
          if (_.isNumber(value)) {
            const roundedValue = _.round(value);
            const date = getJsDateFromExcel(roundedValue);
            return moment.utc(date).format('YYYY-MM-DD');
          } else {
            return value;
          }
        },
        renderEditCell: dateEditor
      });
    } else {
      newColumns.push({
        key: column.key,
        name: column.name,
        width: 150,
        resizable: true,
        renderEditCell: textEditor
      });
    }
  });
  return newColumns;
};

const dataFromRows = (formattedRows) => {
  return _.map(formattedRows, (row) => _.omit(row, ['id']));
};

const ExcelSheetInput = ({field: { name, value }, form: { touched, errors, isValidating, isSubmitting }, columns, onChange, ...props}) => {
  const rows = formatRows(value);
  const formattedColumns = buildDataColumns(columns);
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  // const [errorMessages, setErrorMessages] = useState([]);
  // useEffect(() => {
  //   if (isSubmitting) { setErrorMessages(formattedErrors()); }
  // }, [isValidating, isSubmitting]);


  const buildDummyRow = () => {
    let row = {};
    _.forEach(columns, (column) => { row[column] = ''});
    return row;
  };

  const rowIsBlank = (row) => !Object.values(row).some(x => (x !== null && x !== ''));

  const handleRowsChange = (updatedRows) => {
    onChange(name, updatedRows);
  };

  const onRowAdd = () => {
    let newValue = value.slice();
    const selectedRowId = selectedRows.size > 0 ? [...selectedRows][0].id : rows.length;
    newValue.splice(selectedRowId, 0, buildDummyRow());
    onChange(name, newValue);
  };

  const onRowDelete = () => {
    let newValue = value.slice();
    const selectedRowId = selectedRows.size > 0 ? [...selectedRows][0].id : rows.length;
    newValue.splice(selectedRowId - 1, 1);
    onChange(name, newValue);
  };

  const errorSection = (errors) => {
    if (_.isEmpty(errors)) {
      return <div className="no-errors"></div>;
    } else {
      const validErrors = _.pickBy(errors.list, _.identity);
      console.log('errors', validErrors);
      const errorMapping = _.map(validErrors, (err, index) => {
        const rowNum = parseInt(index) + 1;
        const errorStrings = _.map(Object.values(err), (err) => err.split('.')[1]).join(', ');
        return `Error on row ${rowNum}: ${errorStrings}`;
      });
      return _.map(errorMapping, (errMessage, index) => {
        return (
          <div className={`error-row-${index}`} key={index}>
            {errMessage}
          </div>
        );
      });
    }
  };

  const handleCellClick = (args, event) => {
    setSelectedRows(new Set([args.row]));
    args.selectCell(true);
  }

  return (
    <div className="excel-sheet-container">
      <div className="grid">
        <DataGrid
          className="rdg-light"
          columns={formattedColumns}
          rows={rows}
          selectedRows={selectedRows}
          onCellClick={handleCellClick}
          rowKeyGetter={(row) => row.id }
          onRowsChange={handleRowsChange}
          style={{
          }}
        />
      </div>
      <div className="grid-actions">
        <a className="btn" onClick={onRowAdd}>Add Row</a>
        <a className="btn" onClick={onRowDelete}>Delete Row</a>
      </div>
      <div className="grid-errors">
        {errorSection(errors)}
      </div>
    </div>
  );
};

ExcelSheetInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  columns: PropTypes.array,
  onChange: PropTypes.func
};

ExcelSheetInput.defaultProps = {
  onChange: () => {}
};

export default ExcelSheetInput;
