import _ from 'lodash';

// http://www.strangerstudios.com/sandbox/pagination/diggstyle_perl.txt
export function getPaginationNumbers(numberOfPages, currentPageNumber) {
  const adjacents = 2;
  const pageLimit = 10;
  if (numberOfPages < ((adjacents * 2) + 7)) { // Show all pages
    return _.times(numberOfPages, (index) => {
      return index + 1;
    });
  } else { // Hide some pages
    let beginningEllipses = false;
    let endingEllipses = false;
    let startMiddlePages = 0;
    let endMiddlePages = 0;
    let showPageNumbers = [];

    if (currentPageNumber < ((adjacents * 2) + 1)) { // close to beginning; only hide later pages
      endingEllipses = true;
      startMiddlePages = 2;
      endMiddlePages = pageLimit - 1;
    } else if ((numberOfPages - (adjacents * 2) > currentPageNumber) && (currentPageNumber > (adjacents * 2))) {
      beginningEllipses = true;
      endingEllipses = true;
      startMiddlePages = currentPageNumber - 3;
      endMiddlePages = currentPageNumber + 4;
    } else { // close to end; only hide early pages
      beginningEllipses = true;
      startMiddlePages = numberOfPages - pageLimit + 3;
      endMiddlePages = numberOfPages;
    }

    // compute shown pages
    showPageNumbers.push(1);
    if (beginningEllipses) showPageNumbers.push(-1);
    _.times(endMiddlePages - startMiddlePages, (index) => {
      showPageNumbers.push(startMiddlePages + index);
    });
    if (endingEllipses) showPageNumbers.push(-2);
    showPageNumbers.push(numberOfPages);

    return showPageNumbers;
  }
}
