import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import ImageUpload from '../../lib/forms/ImageUpload';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useMaterialboxEffect } from '../lib/use-materialbox-effect';
import {
  useCreateNetworkDiagramMutation,
  useUpdateNetworkDiagramMutation
} from '../../lib/ascertis-api';

const UpdateNetworkDiagramForm = ({ networkDiagram, system }) => {
  useMaterialboxEffect();
  const [modalMessage, setModalMessage] = useState(undefined);
  const [createNetworkDiagram] = useCreateNetworkDiagramMutation();
  const [updateNetworkDiagram] = useUpdateNetworkDiagramMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    const params = {
      name: formValues.networkDiagram.name,
      type: formValues.networkDiagram.type,
      base64: formValues.networkDiagram.base64
    };

    if (networkDiagram) {
      await updateNetworkDiagram({ systemId: system.id, params }).unwrap()
        .then((payload) => {
          setModalMessage('Network Diagram uploaded successfully.');
        }).catch((error) => {
          setModalMessage(error);
        });
    } else {
      await createNetworkDiagram({ systemId: system.id, params }).unwrap()
        .then((payload) => {
          setModalMessage('Network Diagram uploaded successfully.');
        }).catch((error) => {
          setModalMessage(error);
        });
    }
  };
  const handleClose = () => setModalMessage(undefined);

  let networkDiagramImage;
  if (networkDiagram && networkDiagram.url) {
    networkDiagramImage = <img src={networkDiagram.url} alt="Network Diagram" className="materialboxed" width="200" />;
  }

  const Schema = Yup.object().shape({
    networkDiagram: Yup.object().shape({
      name: Yup.string().required(),
      base64: Yup.string().required()
    })
  });

  return (
    <div id="update-network-diagram-form">
      <Modal id="authentication-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Update Your Network Diagram">
        <Formik
          initialValues={{
            networkDiagram: {
              name: '',
              base64: ''
            }
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  <div className="section">
                    <h6>Network Diagram</h6>
                    <Row>
                      <Column small={12}>
                        <div className="system-logo-image">
                          {networkDiagramImage}
                        </div>
                      </Column>
                    </Row>
                    <Row>
                      <Column small={12}>
                        <Field
                          name="networkDiagram"
                          id="attach-network-diagram"
                          component={ImageUpload}
                          onChange={props.setFieldValue}
                        />
                      </Column>
                    </Row>
                  </div>
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Save</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default UpdateNetworkDiagramForm;
