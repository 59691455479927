import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import UpdateLogoForm from './UpdateLogoForm';

import { useGetSystemQuery, useGetLogoQuery } from '../../lib/ascertis-api';

const LogoPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: logo, isLoading: logoLoading } = useGetLogoQuery({ systemId });

  if (!system || logoLoading) {
    return <Preloader />;
  }

  return (
    <Page>
      <Column small={12}>
        <Section>
          <UpdateLogoForm logo={logo} system={system} />
        </Section>
      </Column>
    </Page>
  );
};

export default LogoPage;
