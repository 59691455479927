import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { removeUser } from '../../lib/userSlice';

const LogoutButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogout = (evt) => {
    evt.preventDefault();
    dispatch(removeUser());
    history.push(`/`);
  };

  return (
    <a href="/home" id="logout" role="button" tabIndex="0" onClick={onLogout}>Log Out</a>
  );
};

export default LogoutButton;
