import React from 'react';
import PropTypes from 'prop-types';

import ButtonNavigation from '../../lib/layout/ButtonNavigation';

const ControlPagination = ({ systemNumber, prevControl, nextControl }) => {
  let backLink;
  if (prevControl) {
    backLink = `/systems/${systemNumber}/controls/${prevControl.key}/implementation`;
  }

  let forwardLink;
  if (nextControl) {
    forwardLink = `/systems/${systemNumber}/controls/${nextControl.key}/implementation`;
  }

  return (
    <ButtonNavigation backDestination={backLink} forwardDestination={forwardLink} />
  );
};

ControlPagination.propTypes = {
  systemNumber: PropTypes.string.isRequired,
  prevControl: PropTypes.shape({
    key: PropTypes.string.isRequired
  }),
  nextControl: PropTypes.shape({
    key: PropTypes.string.isRequired
  })
};

ControlPagination.defaultProps = {
  prevControl: null,
  nextControl: null
};

export default ControlPagination;
