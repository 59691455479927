import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import {
  useGetDocumentControlsQuery,
  useGetDocumentRequirementsQuery,
  useUpdateDocumentMutation
} from '../../lib/ascertis-api';

const DocumentsTableSelectRow = ({ system, control, document, onSelect }) => {
  const rowClasses = `document-table-row document-${document.id}`;

  const [updateDocument] = useUpdateDocumentMutation();
  const { data: controls } = useGetDocumentControlsQuery({
    systemId: system.id, documentId: document.id
  });
  const { data: documentRequirements } = useGetDocumentRequirementsQuery({
    systemId: system.id, documentId: document.id
  });

  let currentRequirementIds = [];
  let currentControlIds = [];
  if (controls && documentRequirements) {
    currentControlIds = _.map(controls, (control) => control.id);
    currentRequirementIds = _.map(documentRequirements, (requirement) => requirement.id);
  }

  const onDocumentAdd = async () => {
    const body = {
      controls: _.concat(currentControlIds, control.id),
      requirements: currentRequirementIds
    };
    await updateDocument({ systemId: system.id, documentId: document.id, params: body }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
        onSelect();
      }).catch((error) => {
        // setModalMessage(error);
      });
  };

  const timestamp = document.uploadedAt;
  const parsedTime = moment(timestamp);
  const displayTime = parsedTime.format("dddd, MMMM Do YYYY");

  return (
    <tr key={`input-${document.id}`} className={rowClasses}>
      <td>
        <a href={document.url} target="_blank">{document.name}</a>
        <br />
        <br />
        <p>Uploaded on <b>{displayTime}</b></p>
      </td>
      <td>
        <a className="btn-small waves-effect" id={`add-${document.id}`} onClick={onDocumentAdd}>
          Add To Control
        </a>
      </td>
    </tr>
  );
};

DocumentsTableSelectRow.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onSelect: PropTypes.func
};

DocumentsTableSelectRow.defaultProps = {
  onSelect: () => {}
};

export default DocumentsTableSelectRow;
