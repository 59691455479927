import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Column from '../../lib/layout/Column';
import CertificationReportForm from './CertificationReportForm';

import { useGetSystemQuery } from '../../lib/ascertis-api';

const CertificationPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });

  if (!system) {
    return <Preloader />;
  }
  return (
    <div id="certification-module" >
      <Column small={2} />
      <Column small={8}>
        <div id="certifier-section">
          <CertificationReportForm system={system} />
        </div>
      </Column>
      <Column small={2} />
    </div>
  );
};

CertificationPage.defaultProps = {
};

export default CertificationPage;
