import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import SelectDropdown from '../../lib/forms/SelectDropdown';
import Preloader from '../../lib/layout/Preloader';
import DocumentUpdateModal from './DocumentUpdateModal';

import {
  useGetDocumentControlsQuery,
  useGetDocumentRequirementsQuery,
  useUpdateDocumentMutation
} from '../../lib/ascertis-api';

const DocumentsTableRequirementRow = ({ system, control, document, requirements }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selected, setSelected] = useState([]);
  const orderedRequirements = _.sortBy(requirements, (requirement) => { return requirement.orderNumber });
  const controlRequirementIds = _.map(requirements, (requirement) => requirement.id);
  const [updateDocument] = useUpdateDocumentMutation();

  const { data: controls } = useGetDocumentControlsQuery({
    systemId: system.id, documentId: document.id
  });
  const { data: documentRequirements, isFetching } = useGetDocumentRequirementsQuery({
    systemId: system.id, documentId: document.id
  });

  let currentControlIds = [];
  let currentRequirementIds = [];
  let currentValues = [];
  let valuesLoaded = false;
  if (controls && documentRequirements) {
    currentControlIds = _.map(controls, (control) => control.id);
    currentRequirementIds = _.map(documentRequirements, (requirement) => requirement.id);
    currentValues = _.intersection(controlRequirementIds, currentRequirementIds);
    valuesLoaded = true;
  }

  useEffect(() => {
    setSelected(currentValues);
    if (valuesLoaded) {
      setDataLoaded(true)
    }
  }, [valuesLoaded]);

  const rowClasses = `document-table-row document-${document.id}`;
  // Used for the select dropdown
  const requirementOptions= _.map(orderedRequirements, (requirement, id) => {
    return { value: requirement.id, label: `Requirement ${id + 1}` };
  });

  const onDocumentDelete = async () => {
    const body = {
      controls: _.difference(currentControlIds, [control.id]),
      requirements: _.difference(currentRequirementIds, controlRequirementIds)
    };
    await updateDocument({ systemId: system.id, documentId: document.id, params: body }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
      }).catch((error) => {
        // setModalMessage(error);
      });
  };

  const onCheckSelect = async (newValues) => {
    const otherReqIds = _.difference(currentRequirementIds, controlRequirementIds);
    const newControlValues = _.uniq(_.concat(currentControlIds, control.id));
    const newReqValues = _.uniq(_.concat(otherReqIds, newValues));
    const body = {
      controls: newControlValues,
      requirements: newReqValues
    };
    await updateDocument({ systemId: system.id, documentId: document.id, params: body }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
        // console.log("DOCUMENT UPDATED");
        setSelected(newReqValues);
      }).catch((error) => {
        // console.log("ERROR");
        // setModalMessage(error);
      });
  };

  let selector = <Preloader/>;
  if (dataLoaded) {
    selector = (
      <SelectDropdown
        key={`select-${document.id}-${control.id}`}
        id={`select-${document.id}-${control.id}`}
        options={requirementOptions}
        value={selected}
        multiSelect={true}
        onSelect={onCheckSelect}
      />
    );
  }

  const timestamp = document.uploadedAt;
  const parsedTime = moment(timestamp);
  const displayTime = parsedTime.format("dddd, MMMM Do YYYY");

  return (
    <tr key={`input-${document.id}`} className={rowClasses}>
      <td>
        <a href={document.url} target="_blank">{document.name}</a>
        <br />
        <br />
        <p>Uploaded on <b>{displayTime}</b></p>
      </td>
      <td>
        <DocumentUpdateModal
          key={`input-${document.id}-${control.id}`}
          system={system}
          control={control}
          document={document}
          requirements={requirements}
        />
      </td>
      <td>
        <a className="btn-small waves-effect" id={`delete-${document.id}`} onClick={onDocumentDelete}>
          Delete
        </a>
      </td>
      <td>
        {selector}
      </td>
    </tr>
  );
};

DocumentsTableRequirementRow.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  onSelect: PropTypes.func
};

DocumentsTableRequirementRow.defaultProps = {
  onSelect: () => {}
};

export default DocumentsTableRequirementRow;
