import React from 'react';
import { Formik, Form, Field } from 'formik';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import FormSelectDropdown from '../../lib/forms/FormSelectDropdown';
import MultiSelectInput from '../../lib/forms/MultiSelectInput';
import SubmitButton from '../../lib/forms/SubmitButton';

const ActionItemFilter = ({ onSelect, isSubmitting }) => {
  const handleSubmit = (formValues, formActions) => {
    onSelect(formValues);
  };

  const sortOptions = [
    { value: 'controlOrderNumber', label: 'Control' },
    { value: 'actualCompletion', label: 'Actual Completion Date' },
    { value: 'projectedCompletion', label: 'Projected Completion Date' },
  ];

  const visibleStateOptions = (sortColumn) => {
    if (sortColumn === 'actualCompletion') {
      return [
        { value: 'complete', label: 'Completed' },
      ];
    } else if (sortColumn === 'projectedCompletion') {
      return [
        { value: 'pending', label: 'Pending' },
        { value: 'past_due', label: 'Past Due' },
        { value: 'complete', label: 'Completed' },
      ];
    } else {
      return [
        { value: 'missing_data', label: 'Incomplete Data' },
        { value: 'pending', label: 'Pending' },
        { value: 'past_due', label: 'Past Due' },
        { value: 'complete', label: 'Completed' },
      ];
    }
  };

  return (
    <div id="action-item-filter" className='filter'>
      <FormContainer title="Filter Items">
        <Formik
          initialValues={{
            sortColumn: 'controlOrderNumber',
            visibleStates: []
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={5}>
                  <Field
                    id="sort-column"
                    name="sortColumn"
                    prompt="Sort By"
                    className="action-item-sort"
                    options={sortOptions}
                    component={FormSelectDropdown}
                  />
                </Column>
                <Column small={5}>
                  <Field
                    id="visible-states"
                    name="visibleStates"
                    prompt="Filter"
                    className="action-item-state-filter"
                    options={visibleStateOptions(props.values.sortColumn)}
                    component={MultiSelectInput}
                  />
                </Column>
                <Column small={2}>
                  <SubmitButton disabled={isSubmitting}>Apply</SubmitButton>
                </Column>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default ActionItemFilter;
