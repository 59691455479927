import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import XLSX from 'xlsx';

import Row from '../../../lib/layout/Row';
import Column from '../../../lib/layout/Column';
import FormContainer from '../../../lib/forms/FormContainer';
import ActionButton from '../../../lib/forms/ActionButton';
import SubmitButton from '../../../lib/forms/SubmitButton';
import FileDragDropUploader from '../../../lib/forms/FileDragDropUploader';
import ExcelSheetInput from '../../../lib/forms/ExcelSheetInput';

import {
  allColumns,
  softwareDataColumns,
  softwareDataRows,
  softwareDataToExcel
} from '../../../lib/data/software-workbook-parser';

import {
  useCreateSoftwareListMutation,
  useUpdateSoftwareListMutation,
  useDeleteSoftwareListMutation,
} from '../../../lib/ascertis-api';

const SoftwareForm = ({ system, softwareList }) => {
  const [savedText, setSavedText] = useState('');
  const [createList] = useCreateSoftwareListMutation();
  const [updateList] = useUpdateSoftwareListMutation();
  const [deleteList] = useDeleteSoftwareListMutation();
  const [workbook, setWorkbook] = useState(undefined);

  const onUpload = (fileAsBinaryString) => {
    const workbook = XLSX.read(fileAsBinaryString, { type: 'binary' });
    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
    setWorkbook(data);
  };

  const handleSubmit = async (formValues, formActions) => {
    if (softwareList) {
      await updateList({ systemId: system.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Software list successfully uploaded.');
        }).catch((error) => {
          setSavedText('Error updating software list.');
        });
    } else {
      await createList({ systemId: system.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Software list successfully uploaded.');
        }).catch((error) => {
          setSavedText('Error updating software list.');
        });
    }
  };

  const handleDelete = async () => {
    await deleteList({ systemId: system.id }).unwrap()
      .then((payload) => {
        setSavedText('Software list successfully deleted.');
      }).catch((error) => {
        setSavedText('Error deleting software list.');
      });
  };

  let initList = [];
  if (softwareList) {
    initList = softwareDataToExcel(softwareList.listData);
  }
  let columns = allColumns();

  let dataArea = (
    <div>Please Upload an Excel Sheet</div>
  );
  if (workbook) {
    columns = softwareDataColumns(workbook);
    initList = softwareDataRows(workbook);
  } else if (!initList) {
    initList = [];
  }

  const Schema = Yup.object().shape({
    list: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        vendor: Yup.string().required(),
      })
    )
  });

  let savedTextSection = undefined;
  if (savedText.length > 0) {
    savedTextSection = <div><br/>{savedText}<br/></div>;
  }

  return (
    <div id="update-software-form">
      <Row>
        <Column small={12}>
          <FormContainer title="Software">
            <Formik
              initialValues={{
                list: initList
              }}
              validationSchema={Schema}
              enableReinitialize
              onSubmit={(values, actions) => {
                return handleSubmit(values, actions);
              }}
            >
              {props => (
                <Form>
                  <Row>
                    <Column small={12}>
                      <div className="divider" />
                      <div className="section">
                        <Row>
                          <Column small={12}>
                            <Field
                              name="list"
                              component={ExcelSheetInput}
                              columns={columns}
                              onChange={props.setFieldValue}
                            />
                          </Column>
                        </Row>
                      </div>
                    </Column>
                  </Row>
                  <Row>
                    <Column small={12}>
                      {savedTextSection}
                    </Column>
                  </Row>
                  <Row className="form-button-container right-align">
                    <Column small={8}></Column>
                    <Column small={2}>
                      <ActionButton className="btn delete-button grey" onClick={handleDelete} buttonText="Delete Data" />
                    </Column>
                    <Column small={2}>
                      <SubmitButton>Submit</SubmitButton>
                    </Column>
                  </Row>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Column>
      </Row>
      <Row>
        <Column small={1}></Column>
        <Column small={10}>
          <Row>
            <Column small={6}>
              XLSX Uploads support files with columns labeled "Name", "Vendor", "Version", and "Patch Level"
              <br/>
              <a href="/software_list_template.xlsx" download>Download Template</a>
            </Column>
            <Column small={6}>
              <FileDragDropUploader onUpload={onUpload} />
            </Column>
          </Row>
        </Column>
        <Column small={1}></Column>
      </Row>
    </div>
  );
};

SoftwareForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  softwareList: PropTypes.object.isRequired
};

SoftwareForm.defaultProps = {
};

export default SoftwareForm;
