import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateControlNoteMutation } from '../../lib/ascertis-api';

const NoteForm = ({ system, control, onSubmit }) => {
  const [createNote] = useCreateControlNoteMutation();

  const handleSubmit = async (formValues) => {
    const params = { scope: 'control', body: formValues.body }
    await createNote({ systemId: system.id, controlId: control.id, params }).unwrap()
      .then((payload) => {
        onSubmit();
      }).catch((error) => {
        onSubmit();
      });
  };

  const Schema = Yup.object().shape({
    body: Yup.string().required()
  });

  // Note:  We are adding the systemId and controlId to the initialValues so that we force
  // reinitialization
  return (
    <div id="new-note-form">
      <FormContainer>
        <Formik
          initialValues={{
            body: '',
            systemId: system.id,
            controlId: control.id,
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field name="body" prompt="Note" component={TextAreaInput} />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Save</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

export default NoteForm;
