import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DocumentsTableSelectRow from './DocumentsTableSelectRow';

const DocumentsSelectTable = ({ system, control, documents, onUpdate }) => {
  const handleAdd = () => onUpdate();

  const documentsRows = _.map(documents, (document, id) => {
    return (
      <DocumentsTableSelectRow
        key={`input-${document.id}`}
        system={system}
        control={control}
        document={document}
        onSelect={handleAdd}
      />
    );
  });

  let tableArea;
  if (_.size(documents) > 0) {
    tableArea = (
      <table id="documents-table" className="striped responsive-table">
        <thead className="header">
          <tr>
            <th>Document Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {documentsRows}
        </tbody>
      </table>
    );
  }

  return (
    <div id="documents-select-table-wrapper">
      {tableArea}
    </div>
  );
};

DocumentsSelectTable.propTypes = {
  system: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  control: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  onUpdate: PropTypes.func
};

DocumentsSelectTable.defaultProps = {
  onUpdate: () => {}
};

export default DocumentsSelectTable;
