import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDropdownEffect } from './lib/use-dropdown-effect';

// This is for rendering select dropdowns that appear OUTSIDE of Formik Forms
const SelectDropdown = ({ id, value, prompt, options, multiSelect, onSelect, ...props }) => {
  const [selected, setSelected] = useState(value);
  const reload = false;
  useDropdownEffect(id, reload, options);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelect = (e) => {
    if (multiSelect) {
      const newValues = _.map(e.target.selectedOptions, (o) => o.value);
      onSelect(newValues);
    } else {
      const newValue = e.target.value;
      onSelect(newValue);
    }
  };

  const optionsHtml = _.map(options, (option) => {
    return (
      <option key={option.value} value={option.value}>{option.label}</option>
    );
  });

  return (
    <div className="input-field" >
      <select id={id} value={selected} multiple={multiSelect} onChange={handleSelect} {...props} >
        <option value="" disabled>Select</option>
        {optionsHtml}
      </select>

      <label htmlFor={name}>{prompt}</label>
    </div>
  );
}

SelectDropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  prompt: PropTypes.string,
  multiSelect: PropTypes.bool,
  onSelect: PropTypes.func
};

SelectDropdown.defaultProps = {
  value: '',
  options: [],
  prompt: '',
  multiSelect: false,
  onSelect: () => {}
};

export default SelectDropdown;
