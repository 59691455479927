import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DocumentsTableRequirementRow from './DocumentsTableRequirementRow';

const DocumentsTable = ({ system, control, documents, requirements }) => {
  const documentsRows = _.map(documents, (document, id) => {
    return (
      <DocumentsTableRequirementRow
        key={`input-${document.id}-${control.id}`}
        system={system}
        control={control}
        requirements={requirements}
        document={document}
      />
    );
  });

  return (
    <table id="documents-table" className="striped responsive-table">
      <thead className="header">
        <tr>
          <th>Document Name</th>
          <th/>
          <th/>
          <th/>
        </tr>
      </thead>
      <tbody>
        {documentsRows}
      </tbody>
    </table>
  );
};

DocumentsTable.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  control: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
};

DocumentsTable.defaultProps = {
};

export default DocumentsTable;
