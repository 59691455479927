import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Page from '../../lib/layout/Page';
import Section from '../../lib/layout/Section';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';

const ExpiredTrialNotification = ({ system }) => {
  const formattedEndDate = moment(system.trialEndsAt).format('YYYY-MM-DD');
  const message = `This trial instance expired on ${formattedEndDate}. To access this instance, please contact ASCERTIS at support@ascertis.solutions to upgrade to a paid version.`;

  return (
    <Page>
      <Column small={12}>
        <Section>
          <div className="system-summary">
            <Column small={2} />
            <Column small={10}>
              <Row>
                <h5>{system.name} (Ref: {system.id})</h5>
              </Row>
              <Row>
                <Column small={2} />
                <Column small={8}>
                  <div className="card large red darken-2">
                    <div className="card-content white-text">
                      <span
                        style={{ textDecorationLine: 'underline' }}
                        className="card-title center-align"
                      >
                        Trial Expired
                      </span>
                      <h5 style={{ textAlign: 'center' }}>{message}</h5>
                    </div>
                  </div>
                </Column>
                <Column small={2} />
              </Row>
            </Column>
          </div>
        </Section>
      </Column>
    </Page>
  );
};

ExpiredTrialNotification.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    completionPercentage: PropTypes.string.isRequired,
    score: PropTypes.string
  }).isRequired
};

export default ExpiredTrialNotification;
