import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import RadioSelect from '../../lib/forms/RadioSelect';
import CheckBox from '../../lib/forms/CheckBox';
import StandardAnswerForm from './StandardAnswerForm';
import CustomAnswerForm from './CustomAnswerForm';
import NotApplicableAnswerForm from './NotApplicableAnswerForm';

const AnswerArea = ({ system, control, implementation, prevControl, nextControl }) => {
  const [notApplicableForm, setNotApplicableForm] = useState(false);
  const [selectedClassification, setClassification] = useState(control.controlClassification);

  useEffect(() => {
    if (implementation) {
      const notApplicable = (implementation.selectedAnswer === 'not_applicable');
      setNotApplicableForm(notApplicable);
    }

    if (control) {
      setClassification(control.controlClassification);
    }
  }, [implementation.id, control.id]);

  const applicableArea = (
    <div id="applicable-area">
      <Section>
        <Row>
          <Column small={4}>
            <StandardAnswerForm
              nextControl={nextControl}
              system={system}
              control={control}
              implementation={implementation}
              classification={selectedClassification}
              answerKey="good_answer"
            />
          </Column>
          <Column small={4}>
            <StandardAnswerForm
              nextControl={nextControl}
              system={system}
              control={control}
              implementation={implementation}
              classification={selectedClassification}
              answerKey="better_answer"
            />
          </Column>
          <Column small={4}>
            <StandardAnswerForm
              nextControl={nextControl}
              system={system}
              control={control}
              implementation={implementation}
              classification={selectedClassification}
              answerKey="best_answer"
            />
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column small={12}>
            <CustomAnswerForm
              nextControl={nextControl}
              system={system}
              control={control}
              implementation={implementation}
              classification={selectedClassification}
            />
          </Column>
        </Row>
      </Section>
    </div>
  );

  const notApplicableArea = (
    <div id="not-applicable-area">
      <Section>
        <Row>
          <Column small={12}>
            <NotApplicableAnswerForm
              nextControl={nextControl}
              system={system}
              control={control}
              implementation={implementation}
            />
          </Column>
        </Row>
      </Section>
    </div>
  );

  const displayArea = notApplicableForm ? notApplicableArea : applicableArea;
  const onChange = () => setNotApplicableForm(!notApplicableForm);

  const commonTooltip = `Common control - a control that is managed by the organization which can be inherited by one or more or the organization's information systems. (e.g. Personnel Screening, Risk Assessment)`;
  const inheritedTooltip = `Inherited control - a control that is managed by an outside organization that can be inherited by one or more organization information systems. (e.g. Physical Security for MSP and CSP, Vulnerability Scanning)`;
  const hybridTooltip = `Hybrid control - a control that is partially implemented by an outside organization and partially implemented by the organizational owners. It can be inherited by one or more organization information systems. (Least Privilege. - the organization defines the roles, and the MSP implements access based on these roles).`;
  const systemTooltip = `System Specific - controls that are unique to a system. (e.g. MFA for systems that contain CUI)`;

  const classificationOptions = [
    { label: 'Common control', value: 'common', tooltip: commonTooltip },
    { label: 'Inherited control', value: 'inherited', tooltip: inheritedTooltip },
    { label: 'Hybrid control', value: 'hybrid', tooltip: hybridTooltip },
    { label: 'System Specific control', value: 'system_specific', tooltip: systemTooltip }
  ];

  const onClassificationSelect = (value) => setClassification(value);

  return (
    <div id="answerArea">
      <Row>
        <Column small={12}>
          <Section>
            <Row>
              <Column small={12}>
                <RadioSelect
                  id="control-classification"
                  options={classificationOptions}
                  selected={selectedClassification}
                  tooltipPosition="top"
                  onSelect={onClassificationSelect}
                />
              </Column>
            </Row>
            <Row>
              <Column small={12}>
                <CheckBox
                  id="na-checkbox"
                  label="This Control is Not Applicable"
                  checked={notApplicableForm}
                  onChange={onChange}
                />
              </Column>
            </Row>
          </Section>
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          { displayArea }
        </Column>
      </Row>
    </div>
  );
};

AnswerArea.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

AnswerArea.defaultProps = {
};

export default AnswerArea;
