import React from 'react';
import { CSVLink } from 'react-csv';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';

const ActionItemsDownloadLink = ({ actionItems }) => {
  const headers = [
    { label: "Item ID", key: "id" },
    { label: "Domain", key: "categoryName" },
    { label: "Control Number", key: "controlIdNumber" },
    { label: "Control Category", key: "controlType" },
    { label: "Control Type", key: "controlClassificationLabel" },
    { label: "Control", key: "controlName" },
    { label: "Requirement", key: "requirementDescription" },
    { label: "Status", key: "status" },
    { label: "Remediation", key: "remediation" },
    { label: "Resources Needed", key: "resourcesNeeded" },
    { label: "Projected Completion", key: "projectedCompletion" },
    { label: "Actual Completion", key: "actualCompletion" },
    { label: "Implementation Notes", key: "controlNotes" },
    { label: "Requirement Notes", key: "requirementNotes" }
  ];

  const filename = "POAM_Items.csv";

  return(
      <div id="action-items-download">
        <CSVLink data={actionItems} headers={headers} filename={filename} className="btn">
          Download POA&M Data
        </CSVLink>
      </div>
  );
}

export default ActionItemsDownloadLink;
