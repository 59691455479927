import React, { useEffect } from 'react';
import M from 'materialize-css';

export function useMaterialboxEffect() {
  const loadMaterialboxJs = () => {
    const options = {};
    const elems = document.querySelectorAll('.materialboxed');
    M.Materialbox.init(elems, options);
  };

  // Has to run on every load
  useEffect(() => {
    loadMaterialboxJs();
  });
}
