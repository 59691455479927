import React from 'react';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';

const NotFound = () => {
  return (
    <div id="not-found" className="portal">
      <main>
        <Page>
          <h4 className="center-align">Sorry, we couldn't find that</h4>
          <Column small={12} medium={4} className="offset-m4">
            <h1 className="center-align">404</h1>
          </Column>
        </Page>
      </main>
    </div>
  );
};

export default NotFound;
