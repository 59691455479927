import _ from 'lodash';

export const hasActiveControl = (controls, currentControlId) => {
  const controlIds = controls.map((control) => control.id);
  const currentValue = new String(currentControlId).valueOf();
  return _.includes(controlIds, currentValue);
};

export const percentImplementationAnswered = (controls) => {
  const totalControls = _.size(controls);
  if (totalControls === 0) return 0;
  const answered = _.filter(controls, (item) => {
    return item.implementationSelected;
  }).length;
  const percentAnswered = answered / totalControls;
  return Math.round(percentAnswered * 100);
};

export const percentRequirementsAnswered = (controls) => {
  const totalControls = _.size(controls);
  if (totalControls === 0) return 0;
  const answered = _.filter(controls, (item) => {
    return item.requirementsSelected;
  }).length;
  const percentAnswered = answered / totalControls;
  return Math.round(percentAnswered * 100);
};

export const buildControlNode = (control, systemId, currentControlId, urlSuffix) => {
  let iconName = 'chevron_right';

  if ((urlSuffix === "requirements") && (control.requirementsSelected)) {
    iconName = 'check_circle';
  } else if ((urlSuffix === "implementation") && (control.implementationSelected)) {
    iconName = 'check_circle';
  }

  const systemLinkEndpoint = `/systems/${systemId}/controls/${control.id}/${urlSuffix}`;
  let classNames = 'secondary-category';
  if (currentControlId === control.id) {
    classNames = 'secondary-category active';
  }

  return (
      {
        key: control.id,
        controlIdNumber: control.controlIdNumber,
        classNames: classNames,
        endpoint: systemLinkEndpoint,
        iconClassNames: "material-icons",
        iconName: iconName,
        text: `${control.controlIdNumber}: ${control.name}`
      }
  );
};

export const buildCategoryNode = (category, controls, systemId, currentControlId, urlSuffix) => {
  const controlItems = controls.map((control) => {
    return buildControlNode(control, systemId, currentControlId, urlSuffix)
  });

  let percentage = 0;
  if (urlSuffix === "requirements") {
    percentage = percentRequirementsAnswered(controls);
  } else if (urlSuffix === "implementation") {
    percentage = percentImplementationAnswered(controls);
  }

  let classNames = 'category collapsible-header';
  let active = false;
  if (hasActiveControl(controls, currentControlId)) {
    classNames = 'category collapsible-header active';
    active = true;
  }

  return {
    key: category.name,
    name: category.name,
    active: active,
    classNames: classNames,
    percentageComplete: percentage,
    controls: controlItems
  }
};

export const orderControls = (controls) => {
  const controlSorter = control => control.orderNumber;
  return _.orderBy(controls, [controlSorter], ['asc'])
}

export const getNistCategories = (controls) => {
  const rawCategories = _.map(controls, (control) => {
    return { id: control.category, name: control.category };
  });
  const uniqCategories = _.uniqBy(rawCategories, (cat) => { return cat.name; });
  const categorySorter = category => category.id.toLowerCase();
  return _.orderBy(uniqCategories, [categorySorter], ['asc'])

};

export const getNistControls = (categoryId, controls) => {
  const foundControls = _.filter(controls, (control) => {
    return control.category === categoryId;
  });
  return orderControls(foundControls);
};

export const getCmmcCategories = (controls) => {
  const rawCategories = _.map(controls, (control) => {
    let levelNumber = 0;
    if (control.cmmcLevelOne) {
      levelNumber = 1;
    } else if (control.cmmcLevelTwo) {
      levelNumber = 2;
    } else if (control.cmmcLevelThree) {
      levelNumber = 3;
    }

    return {
      id: control.category,
      name: `${control.category}: Level ${levelNumber}`,
      levelNumber: levelNumber
    };
  });
  const uniqCategories = _.uniqBy(rawCategories, (cat) => { return `${cat.name}${cat.levelNumber}`; });
  const categorySorter = category => category.id.toLowerCase();
  const levelSorter = 'levelNumber';
  return _.orderBy(uniqCategories, [categorySorter, levelSorter], ['asc', 'asc'])
};

export const getCmmcControls = (categoryId, levelNumber, controls) => {
  const foundControls = _.filter(controls, (control) => {
    let levelMatch = false;
    if (levelNumber === 1) {
      levelMatch = control.cmmcLevelOne
    } else if (levelNumber === 2) {
      levelMatch = control.cmmcLevelTwo
    } else if (levelNumber === 3) {
      levelMatch = control.cmmcLevelThree
    }

    const categoryMatch = control.category === categoryId;
    return levelMatch && categoryMatch;
  });
  return orderControls(foundControls);
};

export const getNistStructure = (controls, systemId, currentControlId, urlSuffix) => {
  const categories = getNistCategories(controls);

  return _.map(categories, (category) => {
    const foundControls = getNistControls(category.id, controls);
    return buildCategoryNode(category, foundControls, systemId, currentControlId, urlSuffix);
  });
};

export const getCmmcStructure = (controls, systemId, currentControlId, urlSuffix) => {
  const categories = getCmmcCategories(controls);

  return _.map(categories, (category) => {
    const foundControls = getCmmcControls(category.id, category.levelNumber, controls);
    return buildCategoryNode(category, foundControls, systemId, currentControlId, urlSuffix);
  });
};

export const buildControlStructure = (system, controls, currentControlId, urlSuffix) => {
  if (!(system) || !(currentControlId)) {
    return [];
  } else if (system.securityModel === 'CMMC') {
    return getCmmcStructure(controls, system.id, currentControlId, urlSuffix);
  } else {
    return getNistStructure(controls, system.id, currentControlId, urlSuffix);
  }
};

export const getPrevControl = (structure, currentControlId = undefined) => {
  const flattenedControls = _.flatten(_.map(structure, structure => structure.controls));
  const keys = _.map(flattenedControls, control => control.key);
  const currentValue = new String(currentControlId).valueOf();
  const index = _.findIndex(keys, idNum => idNum === currentValue);
  if (currentControlId) {
    return flattenedControls[index - 1];
  } else {
    return flattenedControls[0];
  }
};

export const getNextControl = (structure, currentControlId) => {
  const flattenedControls = _.flatten(_.map(structure, structure => structure.controls));
  const keys = _.map(flattenedControls, control => control.key);
  const currentValue = new String(currentControlId).valueOf();
  const index = _.findIndex(keys, idNum => idNum === currentValue);
  if (currentControlId) {
    return flattenedControls[index + 1];
  } else {
    return flattenedControls[0];
  }
};
