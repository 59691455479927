import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

const classNames = require('classnames');

import { getPaginationNumbers } from './pagination-selector';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    const minPage = 1;
    const maxPage = this.props.numberOfPages;
    const targetId = Number(e.target.id);
    if ((targetId >= minPage) && (targetId <= maxPage)) {
      this.props.onClick(targetId);
    }
  }

  render() {
    const { className, numberOfPages, currentPageNumber } = this.props;

    const paginationClasses = classNames({
      'pagination-container': true,
      'center-align': true,
      [`${className}`]: className
    });

    const buttonClass = "pagination-button";

    // Only show at most 10 pages
    const displayPageNumbers = getPaginationNumbers(numberOfPages, currentPageNumber);

    const pageLinks = _.map(displayPageNumbers, (pageNum) => {
      let linkClass = "waves-effect";
      let displayText = String(pageNum);
      if (pageNum === currentPageNumber) linkClass = "active";
      if (pageNum < 0) displayText = '...';

      return (
          <li key={pageNum} className={linkClass}>
            <a className={buttonClass} id={pageNum} onClick={this.handleClick}>
              {displayText}
            </a>
          </li>
      );
    });

    let leftChevronClass = "waves-effect";
    let rightChevronClass = "waves-effect";
    if (currentPageNumber === 1) leftChevronClass = "disabled";
    if (currentPageNumber === numberOfPages) rightChevronClass = "disabled";

    return (
      <div className={paginationClasses} >
        <ul className="pagination">
          <li className={leftChevronClass}>
            <a className={buttonClass} onClick={this.handleClick}>
              <i className="material-icons" id={currentPageNumber - 1}>chevron_left</i>
            </a>
          </li>
          {pageLinks}
          <li className={rightChevronClass}>
            <a className={buttonClass} onClick={this.handleClick}>
              <i className="material-icons" id={currentPageNumber + 1}>chevron_right</i>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

Pagination.propTypes = {
  className: PropTypes.string,
  numberOfPages: PropTypes.number,
  currentPageNumber: PropTypes.number,
  onClick: PropTypes.func
};

Pagination.defaultProps = {
  className: '',
  onClick: () => {}
};

export default Pagination;
