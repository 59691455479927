import useDeepCompareEffect from 'use-deep-compare-effect'
import M from 'materialize-css';
import _ from 'lodash';

export function useDropdownEffect(id, reload, selectOptions, hidden = false) {
  const loadDropdown = () => {
    let options = {};
    const elems = document.querySelectorAll(`#${id}`);
    const instances = M.FormSelect.init(elems, options);

    if (hidden) {
      _.each(instances, (instance) => instance.destroy())
    }
  };

  // We need to use Deep Compare because otherwise the options object
  // will never return as equal.
  useDeepCompareEffect(() => {
    loadDropdown();
  }, [id, reload, selectOptions, hidden]);
}
