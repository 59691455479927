import React from 'react';
import { useParams } from 'react-router-dom';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import Preloader from '../../lib/layout/Preloader';
import ControlSummary from './ControlSummary';
import AnswerArea from './AnswerArea';
import ControlPagination from './ControlPagination';
import NotesSection from './NotesSection';

import {
  useGetSystemQuery,
  useGetControlsQuery,
  useGetControlQuery,
  useGetControlImplementationQuery,
  useGetControlNotesQuery
} from '../../lib/ascertis-api';

import {
  buildControlStructure,
  getPrevControl,
  getNextControl
} from '../../lib/routing/control-list-methods';

const ImplementationPage = () => {
  const { systemId, controlId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: controls } = useGetControlsQuery({ systemId });
  const { data: control } = useGetControlQuery({ systemId, controlId });
  const { data: implementation } = useGetControlImplementationQuery({ systemId, controlId });
  const { data: notes } = useGetControlNotesQuery({ systemId, controlId });

  if ((!system) || (!control) || (!implementation) || (!notes)) return <Preloader />;

  const structure = buildControlStructure(system, controls, controlId, 'implementation');
  const prevControl = getPrevControl(structure, controlId);
  const nextControl = getNextControl(structure, controlId);

  return (
    <div id="implementation">
      <Row>
        <Column small={12}>
          <Section>
            <ControlSummary name={`${control.controlIdNumber}: ${control.name}`} description={control.description} />
          </Section>
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          <AnswerArea
            system={system}
            control={control}
            implementation={implementation}
            prevControl={prevControl}
            nextControl={nextControl}
          />
          <Section>
            <Row>
              <Column small={12}>
                <ControlPagination
                  systemNumber={system.id}
                  prevControl={prevControl}
                  nextControl={nextControl}
                />
              </Column>
            </Row>
          </Section>
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          <NotesSection
            system={system}
            control={control}
            notes={notes}
          />
        </Column>
      </Row>
    </div>
  );
};

ImplementationPage.propTypes = {};
ImplementationPage.defaultProps = {};

export default ImplementationPage;
