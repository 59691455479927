import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import PasswordInput from '../../lib/forms/PasswordInput';
import SubmitButton from '../../lib/forms/SubmitButton';
import { useUpdatePasswordMutation, useGetPasswordChangeUrlQuery } from '../../lib/ascertis-api';
// import { getAuthCredentialsFromUrl } from "app/javascript/utils/jwt-helpers";

const Schema = Yup.object().shape({
  password: Yup.string().required().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/,
    "Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .required()
});
//
// https://staging.ascertisapp.com/user/password/edit?access-token=D4UrmnweG-OTB9vBvk_kLg&client=BkifqPphbPrO_3tFiH2eUA&client_id=BkifqPphbPrO_3tFiH2eUA&config=default&expiry=1699890692&reset_password=true&token=D4UrmnweG-OTB9vBvk_kLg&uid=bryce.senz%40ascertis.solutions

const PasswordChangeForm = () => {
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get('reset_password_token');
  const [tokenMissing, setTokenMissing] = useState(false);
  const [tokenQueried, setTokenQueried] = useState(false);
  const [authHeaders, setAuthHeaders] = useState({});
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updatePassword] = useUpdatePasswordMutation();
  const showModal = !_.isEmpty(modalMessage);
  const { data, isLoading, error } = useGetPasswordChangeUrlQuery({ token }, { skip: tokenQueried });

  useEffect(() => {
    if (_.isEmpty(token)) {
      setTokenMissing(true);
    }
  }, [token]);

  useEffect(() => {
    if (!isLoading) {
      setTokenQueried(true);
    }

    if (!isLoading && !_.isEmpty(data)) {
      setAuthHeaders(data);
    }
  }, [token, isLoading, data]);

  // console.log('tokenMissing', tokenMissing);
  // console.log('error', error);

  // If token is missing or does not pass validation, redirect to the home page
  // if (tokenMissing || error) {
  //   history.push('/login?reset_token_error=true');
  // }

  const handleSubmit = async (formValues) => {
    await updatePassword({ params: formValues, headers: authHeaders }).unwrap()
      .then((payload) => {
        // Take any success actions here
        setModalMessage('Your password has been successfully changed.');
      }).catch((error) => {
        const fullMessages = error.errors.fullMessages;
        setModalMessage(fullMessages);
      });
  };
  const handleClose = () => setModalMessage(undefined);

  return (
    <div id="password-reset-form">
      <Modal id="authentication-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Reset Password">
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field name="password" prompt="Password" component={PasswordInput} />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <Field name="passwordConfirmation" prompt="Password Confirmation" component={PasswordInput} />
                </Column>
              </Row>
              <Row className="form-button-container right-align">
                <SubmitButton>Update Password</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default PasswordChangeForm;
