import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const AddButton = ({ id, className, onClick }) => {
  const buttonClasses = classNames({
    'btn-floating': true,
    'btn-large': true,
    'waves-effect': true,
    'waves-light': true,
    red: true,
    [`${className}`]: className
  });

  return (
    <a
      id={id}
      role="button"
      tabIndex={0}
      className={buttonClasses}
      onClick={onClick}
    >
      <i className="material-icons">add</i>
    </a>
  );
};

AddButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

AddButton.defaultProps = {
  id: 'add-button',
  className: null,
  onClick: () => {}
};

export default AddButton;
