import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import _ from 'lodash';
var moment = require('moment');

import Section from '../../lib/layout/Section';
import ModalForm from '../../lib/layout/ModalForm';
import AddButton from '../../lib/forms/AddButton';
import Note from './Note';
import NoteForm from './NoteForm';

const loadCollapsibleJs = () => {
  const options = { accordion: true };
  const elems = document.querySelectorAll('#notes-collapsible-list');
  M.Collapsible.init(elems, options);
};

const NotesSection = ({ system, control, requirements, notes }) => {
  const [addingNote, setAddingNote] = useState(false);
  const numNotes = _.size(notes);

  useEffect(() => {
    loadCollapsibleJs();
    setAddingNote(false);
  }, [system.id, control.id, numNotes]);

  const onClick = (e) => {
    e.preventDefault();
    setAddingNote(true);
  };

  const closeNoteModal = () => {
    setAddingNote(false);
  };

  const notesList = notes.map((note) => {
    const classNames = 'category collapsible-header';
    const iconName = 'mode_comment';

    return (
      <li key={note.id} id={`note-${note.id}`} className="bold">
        <div className={classNames}>
          <i className="material-icons">
            {iconName}
          </i>
          <div className="note-header">
            {moment(note.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </div>
        </div>
        <div className="collapsible-body">
          <Note system={system} control={control} requirements={requirements} note={note} />
        </div>
      </li>
    );
  });

  return (
    <div id="requirement-notes">
      <Section>
        <h3>Notes</h3>
        <ModalForm id={`requirement-note-modal-${control.id}`} open={addingNote} onClose={closeNoteModal} >
          <NoteForm
            system={system}
            control={control}
            requirements={requirements}
            visible={addingNote}
            onSubmit={closeNoteModal}
          />
        </ModalForm>
        <div className="notes-list" className="note-container collapsible-container">
          <ul id="notes-collapsible-list" className="collapsible" data-collapsible="accordion">
            {notesList}
          </ul>
        </div>
        <div className="note-add">
          <AddButton id="add-note" className="right" onClick={onClick} />
        </div>
      </Section>
    </div>
  );
}

NotesSection.propTypes = {
  system: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  control: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderNumber: PropTypes.number.isRequired
  })).isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
  })).isRequired
};

NotesSection.defaultProps = {};

export default NotesSection;
