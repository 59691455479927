import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import TextInput from '../../lib/forms/TextInput';
import FormContainer from '../../lib/forms/FormContainer';
import SubmitButton from '../../lib/forms/SubmitButton';

const Schema = Yup.object().shape({
});

class CertificationReportForm extends React.Component {
  handleSubmit(formValues, formActions) {
    this.props.onSubmit(formValues);
    formActions.setSubmitting(false);
  }

  render() {
    return (
        <div id="new-certification-report-form">
          <FormContainer title="Generate New Certification Report">
            <Formik
              initialValues={{ }}
              validationSchema={Schema}
              onSubmit={(values, actions) => {
                return this.handleSubmit(values, actions);
              }}
            >
              {props => (
                <Form>
                  <Row>
                    <Column small={12}>
                      <Field name="name" prompt="Name" component={TextInput} />
                    </Column>
                  </Row>
                  <Row>
                    <Column small={12}>
                      <Field name="ato_duration" prompt="ATO Duration Period" component={TextInput} />
                    </Column>
                  </Row>
                  <Row className="form-button-container right-align">
                    <SubmitButton disabled={props.isSubmitting}>Submit</SubmitButton>
                  </Row>
                </Form>

              )}
            </Formik>
          </FormContainer>
        </div>
    );
  }
}

CertificationReportForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func
};

CertificationReportForm.defaultProps = {
  onSubmit: () => {}
};

export default CertificationReportForm;
