import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditNoteForm from './EditNoteForm';

import { useDeleteRequirementNoteMutation } from '../../lib/ascertis-api';

const Note = ({ system, control, requirements, note }) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteNote] = useDeleteRequirementNoteMutation();

  const orderedRequirements = _.sortBy(requirements, (requirement) => { return requirement.orderNumber });
  const requirementOptions= _.map(orderedRequirements, (requirement, id) => {
    return { value: requirement.id, label: `Requirement ${id + 1}` };
  });

  const requirementTags = _.map(note.requirements, (requirementId) => {
    const foundOption = _.find(requirementOptions, option => option.value === requirementId);
    let tag = <div className="requirement-tag-not-found"></div>;
    if (!_.isEmpty(foundOption)) {
      tag = (
        <div className="chip" key={requirementId}>
          {foundOption.label}
        </div>
      )
    }

    return tag;
  });

  const deleteButtonText = deleting ? 'Deleting...' : 'Delete';
  const handleDelete = async () => {
    setDeleting(true);
    await deleteNote({ systemId: system.id, controlId: control.id, noteId: note.id }).unwrap()
      .then((payload) => {
        // Take any success actions here
        // setModalMessage('Account Updated Successfully.');
        setDeleting(false);
      }).catch((error) => {
        // setModalMessage(error);
        setDeleting(false);
      });
  };

  const onClickEdit = (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const handleEditSubmit = () => setEditing(false);

  const classes = `card`;
  const readBody = (
    <div className="note-content">
      <p className="note-body">
        {note.body}
      </p>
      <br />
      <div className="note-requirement-tags">
        {requirementTags}
      </div>
    </div>
  );

  const editBody = (
    <EditNoteForm
      system={system}
      control={control}
      requirements={requirements}
      note={note}
      onSubmit={handleEditSubmit}
    />
  );

  const body = editing ? editBody : readBody;
  const buttonText = editing ? 'Cancel' : 'Edit';

  return (
    <div className={classes}>
      <div className="card-content">
        {body}
      </div>
      <div className="card-action">
        <a href="#" id={`note-edit-${note.id}`} onClick={onClickEdit}>{buttonText}</a>
        <a href="#" id={`note-delete-${note.id}`} onClick={handleDelete}>{deleteButtonText}</a>
      </div>
    </div>
  );
};

Note.propTypes = {
  system: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  control: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderNumber: PropTypes.number.isRequired
  })).isRequired,
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
  }).isRequired
};

Note.defaultProps = {};

export default Note;
