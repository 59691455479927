import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import FormSelectDropdown from '../../lib/forms/FormSelectDropdown';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateSystemMutation } from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  requiredLevel: Yup.number().required().positive().integer(),
  companyName: Yup.string().required()
});

const levelOptions = [
  { label: 'CMMC Level 1', value: 1 },
  { label: 'CMMC Level 2', value: 2 }
];

const NewSystemForm = ({ onSubmit }) => {
  const [createSystem] = useCreateSystemMutation();

  const handleSubmit = async (formValues, formActions) => {
    await createSystem({ params: formValues }).unwrap()
      .then((payload) => {
        onSubmit();
      }).catch((error) => {
        onSubmit();
      });
  };

  return (
    <div id="new-system-form">
      <FormContainer title="Create System">
        <Formik
          initialValues={{ requiredLevel: '', companyName: '' }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field
                    id="required-level"
                    name="requiredLevel"
                    prompt="CMMC Level"
                    options={levelOptions}
                    component={FormSelectDropdown}
                  />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <Field name="companyName" prompt="Company Name" component={TextInput} />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

export default NewSystemForm;
