import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const classNames = require('classnames');

const ButtonNavigation = ({ backDestination, forwardDestination, className, ...props }) => {
  const buttonClasses = classNames({
    'button-navigation': true,
    pagination: true,
    [`${className}`]: className
  });

  const backButtonClasses = backDestination ? "btn-large waves-effect" : "btn-large waves-effect disabled";
  const backTo = backDestination ? backDestination : "#";
  const backButton = (
    <div className="left">
      <Link
          key={backTo}
          to={backTo}
          className={backButtonClasses}
      >
        <i className="material-icons">chevron_left</i>
      </Link>
    </div>
  );

  const forwardButtonClasses = forwardDestination ? "btn-large waves-effect" : "btn-large waves-effect disabled";
  const forwardTo = forwardDestination ? forwardDestination : "#";
  const forwardButton = (
      <div className="right">
        <Link
            key={forwardTo}
            to={forwardTo}
            className={forwardButtonClasses}
        >
          <i className="material-icons">chevron_right</i>
        </Link>
      </div>
  );

  return (
    <div className={buttonClasses}>
      { backButton }
      { forwardButton }
    </div>
  );
};

ButtonNavigation.propTypes = {
  backDestination: PropTypes.string,
  forwardDestination: PropTypes.string,
  className: PropTypes.string
};

ButtonNavigation.defaultProps = {
  backDestination: undefined,
  forwardDestination: undefined,
  className: ''
};

export default ButtonNavigation;
