import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import PasswordInput from '../../lib/forms/PasswordInput';
import SubmitButton from '../../lib/forms/SubmitButton';
import {
  useUpdateUserMutation
} from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  password: Yup.string().required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .required()
});

const AccountUpdateForm = () => {
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateUser] = useUpdateUserMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    await updateUser({ params: formValues }).unwrap()
      .then((payload) => {
        // Take any success actions here
        setModalMessage('Account Updated Successfully.');
      }).catch((error) => {
        setModalMessage(error);
      });
  };
  const handleClose = () => setModalMessage(undefined);

  return (
    <div id="account-update-form">
      <Modal id="account-update-error-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Update Password">
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field name="password" prompt="Password" component={PasswordInput} />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <Field name="passwordConfirmation" prompt="Password Confirmation" component={PasswordInput} />
                </Column>
              </Row>
              <Row className="form-button-container right-align">
                <SubmitButton>Update Account</SubmitButton>
              </Row>
            </Form>

          )}
        </Formik>
      </FormContainer>
    </div>
  )
};

export default AccountUpdateForm;
