import React from 'react';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Card from '../../lib/layout/Card';

const ActionPlanUnavailable = () => {
  return(
    <div id="action-plan-unavailable">
      <Row>
        <Column small={2} />
        <Column small={8}>
          <Card title="POAM Action Plan Unavailable">
            <p>The action plan is not available until the Controls, Requirements, and Threats modules are complete</p>
          </Card>
        </Column>
        <Column small={2} />
      </Row>
    </div>
  );
}

export default ActionPlanUnavailable;
