import React from 'react';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';

const UserAgreementExpired = () => {
  return (
    <div id="terms-conditions" className="portal">
      <main>
        <Page>
          <Column small={12} medium={6} className="offset-m3">
            <h4 className="center-align">You cannot access these features until you've agreed to the terms and conditions.</h4>
            <br />
            <h6>Please reach out to steven.senz@ascertis.solutions in order to have an End User License Agreement sent to you.</h6>
          </Column>
        </Page>
      </main>
    </div>
  );
};

export default UserAgreementExpired;
