import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EditNoteForm from './EditNoteForm';

import { useDeleteControlNoteMutation } from '../../lib/ascertis-api';

const Note = ({ system, control, note }) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteNote] = useDeleteControlNoteMutation();

  const deleteButtonText = deleting ? 'Deleting...' : 'Delete';
  const handleDelete = async () => {
    setDeleting(true);
    await deleteNote({ systemId: system.id, controlId: control.id, noteId: note.id }).unwrap()
      .then((payload) => {
        // Take any success actions here
        // setModalMessage('Account Updated Successfully.');
        setDeleting(false);
      }).catch((error) => {
        // setModalMessage(error);
        setDeleting(false);
      });
  };

  const onClickEdit = (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const handleEditSubmit = () => {
    setEditing(false)
  }

  const classes = `card`;
  const readBody = (
    <div className="note-content">
      <p>
        {note.body}
      </p>
    </div>
  );

  const editBody = (
    <EditNoteForm
      system={system}
      control={control}
      note={note}
      onSubmit={handleEditSubmit}
    />
  );

  const body = editing ? editBody : readBody;
  const buttonText = editing ? 'Cancel' : 'Edit';

  return (
    <div className={classes}>
      <div className="card-content">
        {body}
      </div>
      <div className="card-action">
        <a href="#" id={`note-edit-${note.id}`} onClick={onClickEdit}>{buttonText}</a>
        <a href="#" id={`note-delete-${note.id}`} onClick={handleDelete}>{deleteButtonText}</a>
      </div>
    </div>
  );
};

Note.propTypes = {
  system: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  control: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
  }).isRequired
};

Note.defaultProps = {
};

export default Note;
