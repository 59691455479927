import React from 'react';
import _ from 'lodash';

const buildErrorArrayList = (errors) => _.map((errors), (error, index) => <li key={index}>{error}</li>);
const buildErrorObjectList = (errors) => _.map((errors), (value, key) => {
  let errorMessage = value;
  if (Array.isArray(value)) {
    errorMessage = _.join(value, ', ');
  }
  const fullMessage = `${key}: ${errorMessage}`;
  return <li key={key}>{fullMessage}</li>;
});

export const formatErrors = (errors) => {
  if (Array.isArray(errors)) {
    return (
      <div className="form-errors-wrapper">
        <p>Errors:</p>
        <ul>
          {buildErrorArrayList(errors)}
        </ul>
      </div>
    );
  } else if (_.isObject(errors)) {
    return (
      <div className="form-errors-wrapper">
        <p>Errors:</p>
        <ul>
          {buildErrorObjectList(errors)}
        </ul>
      </div>
    );
  } else {
    return (
      <div className="form-errors-wrapper">
        <p>Errors:</p>
        <ul>
          <li>{errors}</li>
        </ul>
      </div>
    );
  }
}
