import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import UpdateNetworkDiagramForm from './UpdateNetworkDiagramForm';

import { useGetSystemQuery, useGetNetworkDiagramQuery } from '../../lib/ascertis-api';

const NetworkDiagramPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: networkDiagram, isLoading: diagramLoading } = useGetNetworkDiagramQuery({ systemId });

  if (!system || diagramLoading) {
    return <Preloader />;
  }

  return (
    <Page>
      <Column small={12}>
        <Section>
          <UpdateNetworkDiagramForm networkDiagram={networkDiagram} system={system} />
        </Section>
      </Column>
    </Page>
  );
};

export default NetworkDiagramPage;
