import React from 'react';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import ActionItemForm from './ActionItemForm';

const ActionItem = ({ system, actionItem, controlNotes, requirementNotes }) => {
  let cardClasses = 'white';

  switch (actionItem.remediationStatus) {
  case 'pending':
    cardClasses = 'green lighten-5';
    break;
  case 'complete':
    cardClasses = 'green lighten-3';
    break;
  case 'past_due':
    cardClasses = 'red lighten-3';
    break;
  default:
    cardClasses = 'white';
  }

  const controlNotesList = _.map(controlNotes, (note) => {
    return <li key={note.id}>{note.body}</li>;
  });

  const requirementNotesList = _.map(requirementNotes, (note) => {
    return <li key={note.id}>{note.body}</li>;
  });

  return (
    <Row key={`input-${actionItem.id}`}>
      <Column small={12}>
        <div id={`update-action-item-${actionItem.id}-container`} >
          <FormContainer cardClasses={cardClasses}>
            <Row>
              <h6>Failed Requirement</h6>
              <Column small={12}>
                <p>{actionItem.requirementDescription}</p>
              </Column>
            </Row>
            <Row>
              <h6>Control Type</h6>
              <Column small={12}>
                <p>{actionItem.controlClassificationLabel}</p>
              </Column>
            </Row>
            <Row>
              <h6>Implementation Notes</h6>
              <Column small={12}>
                <ul>{controlNotesList}</ul>
              </Column>
            </Row>
            <Row>
              <h6>Requirement Notes</h6>
              <Column small={12}>
                <ul>{requirementNotesList}</ul>
              </Column>
            </Row>
            <ActionItemForm system={system} actionItem={actionItem} />
          </FormContainer>
        </div>
      </Column>
    </Row>
  );
};

export default ActionItem;
