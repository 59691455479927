import React from 'react';

import Row from '../../lib/layout/Row';

const PasswordResetInvalidLink = () => {
  return (
    <div id="password-reset-invalid-link" className="card">
      <div className="card-content">
        <span className="card-title">Invalid Password Reset Link</span>
        <div className="divider" />
        <div className="section">
          <Row>
            <p>That link is no longer valid.</p>
          </Row>
          <Row>
            <p>Please start the password reset process again to receive an email with a valid link.</p>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetInvalidLink;
