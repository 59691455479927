require('es5-shim');
require('es6-shim');
require('es7-shim');

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Roboto', 'sans-serif']
  }
});
