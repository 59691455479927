import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import ImageUpload from '../../lib/forms/ImageUpload';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useMaterialboxEffect } from '../lib/use-materialbox-effect';
import { useCreateLogoMutation, useUpdateLogoMutation } from '../../lib/ascertis-api';

const UpdateLogoForm = ({ logo, system }) => {
  useMaterialboxEffect();
  const [modalMessage, setModalMessage] = useState(undefined);
  const [createLogo] = useCreateLogoMutation();
  const [updateLogo] = useUpdateLogoMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    const params = {
      name: formValues.logo.name,
      type: formValues.logo.type,
      base64: formValues.logo.base64
    };

    if (logo) {
      await updateLogo({ systemId: system.id, params }).unwrap()
        .then((payload) => {
          setModalMessage('Logo uploaded successfully.');
        }).catch((error) => {
          setModalMessage(error);
        });
    } else {
      await createLogo({ systemId: system.id, params }).unwrap()
        .then((payload) => {
          setModalMessage('Logo uploaded successfully.');
        }).catch((error) => {
          setModalMessage(error);
        });
    }
  };
  const handleClose = () => setModalMessage(undefined);

  let logoImage;
  if (logo && logo.url) {
    logoImage = <img src={logo.url} alt="Logo" className="materialboxed" width="200" />;
  }

  const Schema = Yup.object().shape({
    logo: Yup.object().shape({
      name: Yup.string().required(),
      base64: Yup.string().required()
    })
  });

  return (
    <div id="update-logo-form">
      <Modal id="notification-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Update Your Logo">
        <Formik
          initialValues={{
            logo: {
              name: '',
              base64: ''
            }
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  <div className="section">
                    <h6>Company Logo</h6>
                    <Row>
                      <Column small={12}>
                        <div className="system-logo-image">
                          {logoImage}
                        </div>
                      </Column>
                    </Row>
                    <Row>
                      <Column small={12}>
                        <Field
                          name="logo"
                          id="attach-logo"
                          component={ImageUpload}
                          onChange={props.setFieldValue}
                        />
                      </Column>
                    </Row>
                  </div>
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Save</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default UpdateLogoForm;
