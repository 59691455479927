import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Row from '../../lib/layout/Row';
import Section from '../../lib/layout/Section';
import Preloader from '../../lib/layout/Preloader';
import ActionItemsUploadForm from './ActionItemsUploadForm';
import ActionPlanUnavailable from './ActionPlanUnavailable';

import {
  useGetSystemQuery,
  useGetActionPlanQuery,
  useGetActionItemsQuery
} from '../../lib/ascertis-api';

const ActionPlanUploadPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: actionPlan } = useGetActionPlanQuery({ systemId });
  const { data: actionItems } = useGetActionItemsQuery({ systemId });

  if (!system || !actionPlan || !actionItems) {
    return <Preloader />;
  } else if (actionItems.length === 0) {
    return <ActionPlanUnavailable />;
  }

  let title = 'Upload POA&M Items Excel/CSV';

  return (
    <div id="action_plan" className="app-container">
      <main>
        <Page>
          <Row>
            <Column small={1} />
            <Column small={10}>
              <Section>
                <h5>{title}</h5>
              </Section>
              <ActionItemsUploadForm system={system} />
            </Column>
            <Column small={1} />
          </Row>
        </Page>
      </main>
    </div>
  );
};

export default ActionPlanUploadPage;
