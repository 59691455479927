import _ from 'lodash';
import * as Yup from 'yup';
import { yesNoInterpreter } from './parser-helpers';

// import * as equipmentListDictionary from './equipment_list_key_dictionary.json';

const columnList = {
  "cui": "CUI",
  "ipAddress": "IP Address",
  "hostName": "Host Name",
  "description": "Description",
  "operatingSystemFamily": "OS Family",
  "operatingSystemName": "OS Name",
  "operatingSystemVersion": "OS Version",
  "patchLevel": "Patch Level",
  "manufacturer": "Manufacturer",
  "model": "Model",
  "macAddress": "MAC Address",
  "equipmentClass": "Equipment Class",
  "serialNumber": "Serial Number"
};

const equipmentListDictionary = {
  "CUI": "cui",
  "Controlled Unclassified Information": "cui",
  "Controlled Unclassified Info": "cui",
  "IP Address": "ipAddress",
  "IP Addr": "ipAddress",
  "Host Name": "hostName",
  "Host": "hostName",
  "Description": "description",
  "Desc": "description",
  "OS Family": "operatingSystemFamily",
  "Operating System Family": "operatingSystemFamily",
  "OS Name": "operatingSystemName",
  "Operating System Name": "operatingSystemName",
  "OS Version": "operatingSystemVersion",
  "Operating System Version": "operatingSystemVersion",
  "Patch": "patchLevel",
  "Patch Lvl": "patchLevel",
  "Patch Level": "patchLevel",
  "Manufacturer": "manufacturer",
  "Model": "model",
  "MAC Address": "macAddress",
  "Equipment Class": "equipmentClass",
  "Equip Class": "equipmentClass",
  "Serial Number": "serialNumber",
  "Serial Num": "serialNumber",
  "Serial": "serialNumber",
  "Serial No": "serialNumber"
};

function cleanColumnString(string) {
  // look up how to best transform this into a key
  // const lowercase = string.toLowerCase();
  const withoutExtraWhitespace = string.replace(/\s+/g, " ").replace(/^\s|\s$/g, "");
  const onlyAlphanumeric = withoutExtraWhitespace.replace(/[^a-zA-Z]/g, '');
  return onlyAlphanumeric;
}

function stringMatchesColumnName(value) {
  const formattedValue = cleanColumnString(value).toLowerCase();
  const registry = validColumnNames();
  const formattedRegistry = _.map(registry, (val) => {
    return cleanColumnString(val).toLowerCase();
  });
  return _.includes(formattedRegistry, formattedValue);
}

function getFormattedColumnName(string) {
  let columnName;
  const dict = equipmentListDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnName = key;
    }
  });
  return columnName;
}

function getColumnKey(string) {
  let columnKey;
  const dict = equipmentListDictionary;
  _.forEach(dict, (value, key) => {
    const formattedValue = cleanColumnString(string).toLowerCase();
    const formattedKey = cleanColumnString(key).toLowerCase();
    if (formattedValue === formattedKey) {
      columnKey = dict[key];
    }
  });
  return columnKey;
}

export function validColumnNames() {
  const dict = require('./equipment_list_key_dictionary.json');
  return Object.keys(dict);
}

export function getValidIndexes(excelJson) {
  const columnRow = excelJson[0];
  const validIndexes = [];
  columnRow.forEach((value, i) => {
    if (stringMatchesColumnName(value)) {
      validIndexes.push(i);
    }
  });
  return validIndexes;
}

export function equipmentDataToExcel(listData) {
  return _.map(listData, (row) => {
    return {
      ...row,
      cui: yesNoInterpreter(row['cui'])
    };
  });
}

export function getEquipmentData(excelJson) {
  const noEmptyRows = _.filter(excelJson, function(row) {
    return row.length;
  });
  const validIndexes = getValidIndexes(noEmptyRows);
  const outputData = [];
  noEmptyRows.forEach((row) => {
    const newRow = [];
    validIndexes.forEach((index) => {
      newRow.push(row[index]);
    });
    outputData.push(newRow);
  });
  return outputData;
}

export function allColumns() {
  return _.map(columnList, (val, key) => {
    return { name: val, key: key };
  });
}

export function equipmentDataColumns(excelJson) {
  const validData = getEquipmentData(excelJson);
  const headers = validData[0];
  return _.map(headers, (header) => {
    return { name: getFormattedColumnName(header), key: getColumnKey(header) };
  });
}

export function equipmentDataRows(excelJson) {
  const columns = equipmentDataColumns(excelJson);
  let validData = getEquipmentData(excelJson);
  validData.shift();
  const formattedData = [];
  _.forEach(validData, (row) => {
    const newRow = {};
    _.forEach((columns), (column, index) => {
      newRow[`${column.key}`] = row[index];
    });
    formattedData.push(newRow);
  });
  return formattedData;
}
