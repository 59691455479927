import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const classNames = require('classnames');

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.buildListElements = this.buildListElements.bind(this);
    this.buildContentElements = this.buildContentElements.bind(this);
  }

  componentDidMount() {
    // Tab functionality (Materialize)
    if (this.props.className) {
      $(`.${this.props.className}`).tabs();
    } else {
      $('.tabs').tabs();
    }
  }

  buildListElements() {
    const sizeMappings = {
      1: 's10',
      2: 's5',
      3: 's3',
      4: 's2',
      5: 's2'
    }
    const size = _.size(this.props.titles);
    const tabClass = sizeMappings[size] || 's2';
    let firstTab = true;

    return React.Children.map(this.props.children, (comp, index) => {
      let classNames = `tab col ${tabClass}`;
      if (firstTab) {
        classNames = `tab col ${tabClass} active`;
        firstTab = false;
      }
      const title = this.props.titles[index] || comp.key;
      return <li className={classNames} ><a href={`#${comp.key}`}>{title}</a></li>;
    });
  }

  buildContentElements() {
    return React.Children.map(this.props.children, (comp) => {
      return <div id={comp.key} className="col s10 offset-s1">{comp}</div>;
    });
  }

  render() {
    {/*EXAMPLE OUTPUT STRUCTURE*/}
    {/*<div className="row">*/}
      {/*<div className="col s12">*/}
        {/*<ul className="tabs">*/}
          {/*<li className="tab col s3"><a href="#test1">Test 1</a></li>*/}
          {/*<li className="tab col s3"><a className="active" href="#test2">Test 2</a>*/}
          {/*</li>*/}
        {/*<li className="tab col s3 disabled"><a href="#test3">Disabled Tab</a></li>*/}
        {/*<li className="tab col s3"><a href="#test4">Test 4</a></li>*/}
        {/*</ul>*/}
      {/*</div>*/}
      {/*<div id="test1" className="col s12">Test 1</div>*/}
      {/*<div id="test2" className="col s12">Test 2</div>*/}
      {/*<div id="test3" className="col s12">Test 3</div>*/}
      {/*<div id="test4" className="col s12">Test 4</div>*/}
    {/*</div>*/}

    const { className } = this.props;
    const tabClasses = classNames({
      tabs: true,
      [`${className}`]: className
    });
    const listElements = this.buildListElements();
    const contentElements = this.buildContentElements();

    return (
        <div className="row">
          <div className="col s10 offset-s1">
            <ul className={tabClasses}>
              {listElements}
            </ul>
          </div>
          {contentElements}
        </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Tabs.defaultProps = {
  className: '',
  titles: [],
  children: null
};

export default Tabs;
