import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const ControlList = ({ structure }) => {
  const [initialized, setInitialized] = useState(false);
  const [activeKey, setActiveKey] = useState(undefined);
  const loadCollapsible = () => {
    const options = { accordion: true };
    const elems = document.querySelectorAll('#control-list');
    M.Collapsible.init(elems, options);
  };

  useEffect(() => {
    structure.map((category, index) => {
      if (category.active && !activeKey && !initialized) {
        setActiveKey(category.key)
        setInitialized(true)
      }
    });
  }, [structure])

  const refreshCollapsible = () => {
    const elems = document.querySelectorAll('#control-list');
    const instance = M.Collapsible.getInstance(elems[0]);
    structure.map((category, index) => {
      if (activeKey) {
        if (category.key === activeKey) {
          instance.open(index);
        } else {
          instance.close(index);
        }
      }
    });
  };

  useEffect(() => {
    loadCollapsible();
  }, []);

  useEffect(() => {
    refreshCollapsible();
  });

  const buildControlNode = (control) => {
    return (
      <li key={control.key} className={control.classNames}>
        <Link key={control.key} to={control.endpoint} className={`control-${control.key}`}>
          <i className={control.iconClassNames}>
            {control.iconName}
          </i>
          {control.text}
        </Link>
      </li>
    );
  };

  const buildQuestionNav = (controlsData) => {
    return controlsData.map((control) => buildControlNode(control));
  };

  const handleClick = (categoryKey) => {
    if (categoryKey === activeKey) {
      setActiveKey(undefined);
    } else {
      setActiveKey(categoryKey);
    }
  }

  const listItems = structure.map((category) => {
    return (
      <li key={category.key} className="bold" onClick={() => handleClick(category.key)}>
        <div className={category.classNames}>
          <span className="new badge left" data-badge-caption="%">
            {category.percentageComplete}
          </span>
          <div className="category-name">
            {category.name}
          </div>
        </div>
        <div className="collapsible-body">
          <ul key={category.name}>
            {buildQuestionNav(category.controls)}
          </ul>
        </div>
      </li>
    );
  });

  return (
    <div className="category-list" className="collapsible-container">
      <ul id="control-list" className="collapsible" data-collapsible="accordion">
        {listItems}
      </ul>
    </div>
  );
};

ControlList.propTypes = {
  structure: PropTypes.arrayOf(PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      controls: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }))
    }))
  }))
};

ControlList.defaultProps = {};

export default ControlList;
