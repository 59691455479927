import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import ModalForm from '../../lib/layout/ModalForm';
import FormContainer from '../../lib/forms/FormContainer';
import FileUpload from '../../lib/forms/FileUpload';
import ActionButton from '../../lib/forms/ActionButton';
import SubmitButton from '../../lib/forms/SubmitButton';

import {
  useGetDocumentControlsQuery,
  useGetDocumentRequirementsQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation
} from '../../lib/ascertis-api';
import * as Yup from "yup";

const Schema = Yup.object().shape({
  document: Yup.object().shape({
    name: Yup.string().required(),
    base64: Yup.string().required()
  })
});

const DocumentUpdateModal = ({ system, control, requirements, document }) => {
  const [updatingDocument, setUpdatingDocument] = useState(false);
  const controlRequirementIds = _.map(requirements, (requirement) => requirement.id);
  const [createDocument] = useCreateDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  const { data: documentControls } = useGetDocumentControlsQuery({
    systemId: system.id, documentId: document.id
  });
  const { data: documentRequirements } = useGetDocumentRequirementsQuery({
    systemId: system.id, documentId: document.id
  });

  const currentControlIds = _.map(documentControls, (control) => control.id);
  const currentRequirementIds = _.map(documentRequirements, (requirement) => requirement.id);

  const handleSubmit = async (formValues) => {
    const newBody = {
      name: formValues.document.name,
      type: formValues.document.type,
      base64: formValues.document.base64,
      controls: [control.id],
      requirements: _.intersection(controlRequirementIds, currentRequirementIds)
    };
    const updateBody = {
      controls: _.difference(currentControlIds, [control.id]),
      requirements: _.difference(currentRequirementIds, controlRequirementIds)
    };

    await updateDocument({ systemId: system.id, documentId: document.id, params: updateBody }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
      }).catch((error) => {
        // setModalMessage(error);
      });

    await createDocument({ systemId: system.id, params: newBody }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
      }).catch((error) => {
        // setModalMessage(error);
      });
  };

  const handleOpen = () => setUpdatingDocument(true);
  const handleClose = () => setUpdatingDocument(false);

  return (
    <div>
      <ModalForm id={`update-${document.id}-modal`} open={updatingDocument} onClose={handleClose}>
        <p>This will replace the existing document (and all of its requirement mappings) for this control, and/or across all controls</p>
        <Row>
          <Column small={12}>
            <FormContainer title="Update Your Document">
              <Formik
                initialValues={{
                  document: {
                    name: '',
                    base64: ''
                  }
                }}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={(values, actions) => {
                  return handleSubmit(values, actions);
                }}
              >
                {props => (
                  <Form role="form">
                    <Row>
                      <Column small={12}>
                        <Field
                          name="document"
                          id="attach-document"
                          component={FileUpload}
                          onChange={props.setFieldValue}
                        />
                      </Column>
                    </Row>

                    <Row className="form-button-container right-align">
                      <SubmitButton>Save</SubmitButton>
                      <ActionButton
                        id={`close-update-${document.id}`}
                        className="btn medium"
                        onClick={handleClose}
                        buttonText="Close"
                      />
                    </Row>
                  </Form>
                )}
              </Formik>
            </FormContainer>
          </Column>
        </Row>
      </ModalForm>
      <a className="btn-small waves-effect" id={`update-${document.id}`} onClick={handleOpen}>
        Update
      </a>
    </div>
  );
};

DocumentUpdateModal.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  control: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  document: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DocumentUpdateModal.defaultProps = {
};

export default DocumentUpdateModal;
