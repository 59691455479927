import React, { useEffect } from 'react';
import M from 'materialize-css';

export function useDropdownEffect() {
  const loadDropdownJs = () => {
    const options = {};
    const elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, options);
  };

  useEffect(() => {
    loadDropdownJs();
  }, []);
}
