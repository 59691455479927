import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Page from '../../lib/layout/Page';
import Section from '../../lib/layout/Section';
import Row from '../../lib/layout/Row';
import ModalForm from '../../lib/layout/ModalForm';
import AddButton from '../../lib/forms/AddButton';

import NewSystemForm from './NewSystemForm';
import { useGetSystemsQuery } from '../../lib/ascertis-api';
import { selectUser } from '../../lib/userSlice';

const SystemsPage = () => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const { data: systems } = useGetSystemsQuery();
  const [addingSystem, setAddingSystem] = useState(false);
  const isSuperAdmin = !!(user && user.superAdmin);

  const onClick = (e) => {
    e.preventDefault();
    setAddingSystem(true);
  };

  const closeSystemModal = () => {
    setAddingSystem(false);
  };

  const handleSystemSelect = (id) => history.push(`/systems/${id}`);

  const listItems = _.map(systems, (system) => {
    return (
      <tr key={system.id}>
        <td>{system.id}</td>
        <td>{system.securityModel}</td>
        <td>{system.name}</td>
        <td><a role="link" className="view-system" onClick={() => handleSystemSelect(system.id)}>View</a></td>
      </tr>
    );
  });

  let buttonArea;
  if (isSuperAdmin) {
    buttonArea = <AddButton id="add-system" className="right" onClick={onClick} />;
  }

  return (
    <div id="systems-page" className="portal">
      <Page>
        <div id="system-list">
          <Section>
            <ModalForm id="new-note-modal" open={addingSystem} onClose={closeSystemModal}>
              <NewSystemForm onSubmit={closeSystemModal} />
            </ModalForm>

            <Row>
              <div className="header">
                <h4>Existing Systems</h4>
              </div>
            </Row>
            <Row>
              <table className="bordered highlight responsive-table">
                <thead>
                <tr>
                  <th>System Number</th>
                  <th>Security Model</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { listItems }
                </tbody>
              </table>
            </Row>

            <Row>
              {buttonArea}
            </Row>
          </Section>
        </div>
      </Page>
    </div>
  );
};

export default SystemsPage;
