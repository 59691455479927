import React from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const classNames = require('classnames');

class Tooltip extends React.Component {
  componentDidMount() {
    // Tooltip functionality (Materialize)
    const options = {
      position: this.props.position, // Options are 'top', 'bottom', 'left', 'right'
      inDuration: 300, // Enter transition duration.
      outDuration: 250 // Exit transition duration.
    };
    const elems = document.querySelectorAll('.tooltip');
    M.Tooltip.init(elems, options);
  }

  render() {
    const modalClasses = classNames({
      tooltip: true,
      [`${this.props.className}`]: this.props.className
    });

    return (
        <span className={modalClasses} data-position={this.props.position} data-tooltip={this.props.text}>
          { this.props.children }
        </span>
    );
  }
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Tooltip.defaultProps = {
  position: 'top',
  text: '',
  className: '',
  children: null
};

export default Tooltip;
