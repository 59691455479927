import React from 'react';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';

const UnsubscribeSuccessful = () => {
  return (
    <div id="unsubscribe-successful" className="portal">
      <main>
        <Page>
          <Column small={12} medium={4} className="offset-m4">
            <h5 className="center-align">You have been successfully unsubscribed from all Ascertis marketing emails.</h5>
          </Column>
        </Page>
      </main>
    </div>
  );
};

export default UnsubscribeSuccessful;
