import React from 'react';
import { Link } from 'react-router-dom';

import Row from '../../lib/layout/Row';

const AccountLinks = () => {
  return (
    <div id="account-links" className="card red lighten-2">
      <div className="card-content white-text">
        <span className="card-title">Account Help</span>
        <div className="divider" />
        <div className="section">
          <Row className="valign-wrapper">
            <Link to="/user/password" className="white-text">
              <i className="material-icons">info_outline</i>
              Reset my password
            </Link>
          </Row>
          <Row>
            <Link to="/user/unlock" className="white-text">
              <i className="material-icons">lock_open</i>
              Unlock my account
            </Link>
          </Row>
          <Row>
            <a href="https://cmmcworkshop.com/welcome-to-the-cmmc-workshop/" target="_blank" className="white-text">
              <i className="material-icons">speaker_notes</i>
              CMMC Workshop
            </a>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AccountLinks;
