import * as Yup from 'yup';
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, 'phone', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Must be a phone number',
    test: (value) => {
      try {
        if ((!value) || (value.length === 0)) {
          return true;
        }
        const phone = phoneUtil.parse(value, 'US');
        return phoneUtil.isValidNumber(phone);
      } catch (e) {
        return false;
      }
    }
  });
});
