import React from 'react';
import PropTypes from 'prop-types';
import 'jquery-circle-progress/dist/circle-progress';

class ProgressCircle extends React.Component {
  componentDidMount() {
    let rawValue = 0.0;

    if (this.props.value) {
      rawValue = this.props.value;
    }

    // Animate the circle from zero to the current value
    $('#circle').circleProgress({
      value: rawValue,
      size: 120,
      fill: {
        gradient: ['red', 'green']
      }
    });

    $('#circle').on('circle-animation-progress', function(event, progress, stepValue) {
      $(this).find('.value').text(`${(100 * stepValue).toFixed()}%`);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      $('#circle').circleProgress({
        value: nextProps.value,
        animationStartValue: this.props.value,
        size: 120,
        fill: {
          gradient: ['red', 'green']
        }
      });

      $('#circle').on('circle-animation-progress', function(event, progress, stepValue) {
        $(this).find('.value').text(`${(100 * stepValue).toFixed()}%`);
      });
    }
  }

  render() {
    let descriptionTag;
    if (this.props.description) {
      descriptionTag = <div className="chip">{this.props.description}</div>;
    }

    return (
      <div id="circle-container">
        <div id="circle">
          <span className="value" />
        </div>
        <div id="chip-container">
          {descriptionTag}
        </div>
      </div>
    );
  }
}

ProgressCircle.propTypes = {
  value: PropTypes.number,
  description: PropTypes.string
};

ProgressCircle.defaultProps = {
  value: 0,
  description: undefined
};

export default ProgressCircle;
