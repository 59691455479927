import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionButton from '../../lib/forms/ActionButton';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import ModalForm from '../../lib/layout/ModalForm';

import DocumentUploadForm from './DocumentUploadForm';
import DocumentsSelectTable from './DocumentsSelectTable';

const DocumentSelectorModal = ({ system, control, documents }) => {
  const [addingDocument, setAddingDocument] = useState(false);

  const handleOpen = () => setAddingDocument(true);
  const handleClose = () => setAddingDocument(false);
  const handleUpload = () => setAddingDocument(false);

  return (
    <div>
      <h5>Link or add documents</h5>
      <ModalForm id="document-select-modal" open={addingDocument} onClose={handleClose}>
        <Row>
          <DocumentsSelectTable system={system} control={control} documents={documents} onUpdate={handleUpload} />
        </Row>
        <Row>
          <Column small={12}>
            <DocumentUploadForm
              system={system}
              control={control}
              onUpload={handleUpload}
              onClose={handleClose}
            />
          </Column>
        </Row>
      </ModalForm>
      <ActionButton id="open-document-select" className="btn medium" onClick={handleOpen} buttonText="Add Document" />
    </div>
  );
};

DocumentSelectorModal.propTypes = {
  system: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  control: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
};

DocumentSelectorModal.defaultProps = {};

export default DocumentSelectorModal;
