import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter, Link } from 'react-router-dom';
import withSizes from 'react-sizes';
import _ from 'lodash';

const getBreadcrumbLinks = (location) => {
  const pathname = location.pathname;
  const components = pathname.split('/');
  let links = [];

  if (pathname === '/') {
    links.push({ name: 'HOME', link: '/' });
  } else {
    links = _.map(components, (component) => {
      const partialLink = `/${component}`;
      const priorPath = pathname.split(partialLink)[0];
      // We have to format the component name
      let name = component
      if (name.length === 0) name = 'Home';
      return { name: name.toUpperCase(), link: `${priorPath}${partialLink}` };
    });
  }
  return links;
};

const Breadcrumbs = ({ requiresSmallBreadcrumbs }) => {
  const location = useLocation();
  const fakeProps = { location: location };
  const links = getBreadcrumbLinks(location);
  let linkMap = [];

  // If there are more than four elements in the array, just use the first and last two
  if (links.length > 4) {
    linkMap.push(<Link key={0} to={links[0].link} className="breadcrumb">{links[0].name}</Link>);
    linkMap.push(<Link key={1} to='#' className="breadcrumb">...</Link>);
    linkMap.push(<Link key={2} to={links[2].link} className="breadcrumb">{links[2].name}</Link>);

    if (!requiresSmallBreadcrumbs) {
      let lastTwoLinks = _.takeRight(links, 2);
      linkMap.push(<Link key={3} to={lastTwoLinks[0].link} className="breadcrumb">{lastTwoLinks[0].name}</Link>);
      linkMap.push(<Link key={4} to={lastTwoLinks[1].link} className="breadcrumb">{lastTwoLinks[1].name}</Link>);
    }
  } else {
    linkMap = _.map(links, (obj, index) => {
      return <Link key={index} to={obj.link} className="breadcrumb">{obj.name}</Link>;
    });
  }

  return (
    <nav className="breadcrumbs">
      <div className="nav-wrapper">
        <div className="col s12">
          {linkMap}
        </div>
      </div>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }))
};

Breadcrumbs.defaultProps = {
  links: []
};

const mapSizesToProps = ({ width }) => ({
  requiresSmallBreadcrumbs: width < 640,
});

export default withRouter(withSizes(mapSizesToProps)(Breadcrumbs));
