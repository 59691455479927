import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import Page from '../../lib/layout/Page';
import Preloader from '../../lib/layout/Preloader';

import {
  useGetSystemQuery,
  useGetControlsQuery
} from '../../lib/ascertis-api';

const ControlsRedirectPage = ({ suffix }) => {
  const history = useHistory();
  const { systemId } = useParams();
  const { data: system, isLoading: systemLoading } = useGetSystemQuery({ systemId });
  const { data: controls, isLoading: controlsLoading } = useGetControlsQuery({ systemId });
  const loading = systemLoading || controlsLoading;

  useEffect(() => {
    if (system && controls) {
      const firstControl = _.first(controls);
      history.push(`/systems/${systemId}/controls/${firstControl.id}/${suffix}`);
    }
  }, [systemId, loading]);

  if (!system || !controls) {
    return <Preloader />;
  }

  return (
    <div id="controls" className="app-container">
      <main>
        <Page />
      </main>
    </div>
  );
};

ControlsRedirectPage.defaultProps = {
  suffix: 'implementation'
};

export default ControlsRedirectPage;
