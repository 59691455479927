import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import { apiSlice } from '../lib/ascertis-api';
import createRootReducer from './reducers';
const configureCustomStore = (preloadedState) => {
  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      const customMiddleware = [
        thunk,
        apiMiddleware,
        apiSlice.middleware
      ];
      return getDefaultMiddleware().concat(customMiddleware);
    }
  });

  setupListeners(store.dispatch)

  return store;
}
export default configureCustomStore;

