import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import Card from '../../lib/layout/Card';
import Preloader from '../../lib/layout/Preloader';
import RequirementForm from './RequirementForm';
import DocumentSelectorModal from './DocumentSelectorModal';
import DocumentsTable from './DocumentsTable';
import NotesSection from './NotesSection';
import ControlPagination from './ControlPagination';

import {
  useGetSystemQuery,
  useGetControlsQuery,
  useGetControlQuery,
  useGetControlImplementationQuery,
  useGetControlRequirementsQuery,
  useGetDocumentsQuery,
  useGetRequirementNotesQuery
} from '../../lib/ascertis-api';

import {
  buildControlStructure,
  getPrevControl,
  getNextControl
} from '../../lib/routing/control-list-methods';

const RequirementsPage = () => {
  const { systemId, controlId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: controls } = useGetControlsQuery({ systemId });
  const { data: control } = useGetControlQuery({ systemId, controlId });
  const { data: implementation } = useGetControlImplementationQuery({ systemId, controlId });
  const { data: requirements } = useGetControlRequirementsQuery({ systemId, controlId });
  const { data: documents } = useGetDocumentsQuery({ systemId }, { pollingInterval: 3000 });
  const { data: notes } = useGetRequirementNotesQuery({ systemId, controlId });

  if ((!system) || (!control) || (!implementation) || (!requirements) || (!documents) || (!notes)) {
    return <Preloader />;
  }

  const structure = buildControlStructure(system, controls, controlId, 'implementation');
  const prevControl = getPrevControl(structure, controlId);
  const nextControl = getNextControl(structure, controlId);
  const controlDocuments = _.filter(documents, (doc) => _.includes(doc.controls, controlId));

  let selectedAnswer;
  let formArea;
  let documentUploadArea;
  let documentsTable;
  if (implementation.status === 'selected') {
    selectedAnswer = (
      <Card title="Answer selected on Control Assessment" className="green lighten-1">
        {implementation.selectedAnswerOption}
      </Card>
    );

    formArea = (
      <RequirementForm
        system={system}
        control={control}
        nextControl={nextControl}
        requirements={requirements}
      />
    );

    const allowsArtifacts = true;
    // const allowsArtifacts = systemsSelectors.hasAvailableFeature(system, 'artifacts')
    if (allowsArtifacts) {
      documentUploadArea = (
        <DocumentSelectorModal
          system={system}
          control={control}
          documents={_.xor(documents, controlDocuments)}
        />
      );

      documentsTable = (
        <DocumentsTable
          system={system}
          control={control}
          requirements={requirements}
          documents={controlDocuments}
        />
      );
    }
  } else {
    selectedAnswer = (
      <Card title="Implementation of Control not defined" className="red lighten-1">
        <p>The implementation details of this control have yet to be selected.  Please do so in the Controls section of this application.</p>
        <br />
        <p>Requirements cannot be selected until the implementation details are selected.</p>
      </Card>
    );
  }

  return (
    <div id="requirements">
      <Row>
        <Column small={12}>
          <Section>
            <h5>{control.controlIdNumber}: {control.name}</h5>
            <p>{control.description}</p>
          </Section>
          <Section>
            {selectedAnswer}
          </Section>
          <Section>
            {formArea}
          </Section>
          <Section>
            {documentUploadArea}
          </Section>
          <Section>
            {documentsTable}
          </Section>
          <Section>
            <ControlPagination
              systemNumber={system.id}
              prevControl={prevControl}
              nextControl={nextControl}
            />
          </Section>
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          <NotesSection
            system={system}
            control={control}
            requirements={requirements}
            notes={notes}
          />
        </Column>
      </Row>
    </div>
  );
};

RequirementsPage.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

RequirementsPage.defaultProps = {
};

export default RequirementsPage;
