import React from 'react';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';

import ActionItem from './ActionItem';

const buildItems = (system, items, notes) => {
  return _.map(items, (item) => {
    const controlNotes = _.filter(notes, (note) => {
      return (note.noteScope === 'control') && (note.notableId === item.controlNumber);
    });

    const requirementNotes = _.filter(notes, (note) => {
      return (note.noteScope === 'requirement') && _.includes(note.requirements, item.requirementNumber);
    });

    return (
      <Row key={item.id}>
        <Column small={12}>
          <ActionItem
            key={item.id}
            system={system}
            actionItem={item}
            controlNotes={controlNotes}
            requirementNotes={requirementNotes}
          />
        </Column>
      </Row>
    );
  });
};

const ActionItemList = ({ system, actionItems, notes }) => {
  const actionItemsAreas = _.map(actionItems, (items, index) => {
    const controlIdNumber = items[0].controlIdNumber;
    const controlName = items[0].controlName;
    const sortedItems = _.sortBy(items, 'requirementOrderNumber');
    return (
      <Row key={index}>
        <Column small={12}>
          <Section>
            <h5>{`${controlIdNumber} - ${controlName}`}</h5>
            {buildItems(system, sortedItems, notes)}
          </Section>
        </Column>
      </Row>
    );
  });

  return(
    <div id="action-item-list">
      {actionItemsAreas}
    </div>
  );
}

export default ActionItemList;
