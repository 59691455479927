import React from 'react';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Tabs from '../../lib/layout/Tabs';
import AccountUpdateForm from './AccountUpdateForm';
import AccountSettingsForm from './AccountSettingsForm';

const AccountManagementPage = () => {
  const titles = ['Settings', 'Password'];

  return (
    <div id="account-management-page">
      <main>
        <Page>
          <Column small={12}>
            <Tabs titles={titles}>
              <AccountSettingsForm key="settings"/>
              <AccountUpdateForm key="password"/>
            </Tabs>
          </Column>
        </Page>
      </main>
    </div>
  )
};

export default AccountManagementPage;
