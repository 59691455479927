import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Container = ({ className, ...props }) => {
  const containerClasses = classNames({
    container: true,
    [`${className}`]: className
  });

  return (
    <div className={containerClasses}>
      {props.children}
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Container.defaultProps = {
  className: '',
  children: null
};

export default Container;
