import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Ability } from '@casl/ability';
import _ from 'lodash';

// import { Can, AbilityContext } from '../../modules/ability-context';
import Section from '../../lib/layout/Section';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Card from '../../lib/layout/Card';
import SystemSummary from './SystemSummary';
import ExpiredTrialNotification from './ExpiredTrialNotification';

import { useGetSystemQuery, useGetSystemPermissionsQuery } from '../../lib/ascertis-api';

const hasAvailableModule = (system, moduleName) => _.includes(system.availableModules, moduleName);

const systemInfoUrl = (system) => {
  if (hasAvailableModule(system, 'system_info')) {
    return `/systems/${system.id}/description`;
  } else {
    return `/systems/${system.id}`;
  }
};

const controlsUrl = (system) => {
  if (hasAvailableModule(system, 'implementations')) {
    return `/systems/${system.id}/controls`;
  } else {
    return `/systems/${system.id}`;
  }
};

const requirementsUrl = (system) => {
  if (hasAvailableModule(system, 'requirements')) {
    return `/systems/${system.id}/requirements`;
  } else {
    return `/systems/${system.id}`;
  }
};

const threatsUrl = (system) => {
  if (hasAvailableModule(system, 'threats')) {
    return `/systems/${system.id}/threats`;
  } else {
    return `/systems/${system.id}`;
  }
};

const capabilitiesUrl = (system) => {
  // TODO: This should be driven my module availability on the back-end
  return `/systems/${system.id}/capabilities`;
  // if (hasAvailableModule(system, 'capabilities')) {
  //   return `/systems/${system.id}/capabilities`;
  // } else {
  //   return `/systems/${system.id}`;
  // }
};

const processesUrl = (system) => {
  if (hasAvailableModule(system, 'capabilities')) {
    return `/systems/${system.id}/processes`;
  } else {
    return `/systems/${system.id}`;
  }
};

const actionPlanUrl = (system) => {
  if (hasAvailableModule(system, 'action_plan')) {
    return `/systems/${system.id}/action_plan`;
  } else {
    return `/systems/${system.id}`;
  }
};

const reportsUrl = (system) => {
  if (hasAvailableModule(system, 'reports')) {
    return `/systems/${system.id}/reports`;
  } else {
    return `/systems/${system.id}`;
  }
};

const certificationUrl = (system) => {
  if (hasAvailableModule(system, 'certification')) {
    return `/systems/${system.id}/certification`;
  } else {
    return `/systems/${system.id}`;
  }
};

const SystemPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId }, { refetchOnMountOrArgChange: true });
  const { data: permissions } = useGetSystemPermissionsQuery({ systemId });
  let userPermissions = [];
  if (permissions) {
    userPermissions = permissions.permissions
  }
  const ability = new Ability(userPermissions);

  if (!system) { return <div>Loading</div>; }

  const systemInfoAvailable = hasAvailableModule(system, 'system_info');
  const controlsAvailable = hasAvailableModule(system, 'implementations');
  const requirementsAvailable = hasAvailableModule(system, 'requirements');
  const threatsAvailable = hasAvailableModule(system, 'threats');
  const actionPlanAvailable = hasAvailableModule(system, 'action_plan');
  const reportsAvailable = hasAvailableModule(system, 'reports');
  const certificationAvailable = hasAvailableModule(system, 'certification');
  const canEditProcesses = ability.can('update', 'DomainProcess');

  let showCmmcModules = false;
  if (system.securityModel === 'CMMC') { showCmmcModules = true; }

  const firstRow = (
    <Row>
      <Column medium={4}>
        <Card
          className="system-card"
          title="System Info"
          linkClassName={systemInfoAvailable ? "unlocked" : "locked"}
          linkTo={systemInfoAvailable ? systemInfoUrl(system) : undefined }
          linkTitle={systemInfoAvailable ? "Start" : "Locked"}
        />
      </Column>
      <Column medium={4}>
        <Card
          className="controls-card"
          title="Controls"
          linkClassName={controlsAvailable ? "unlocked" : "locked"}
          linkTo={controlsAvailable ? controlsUrl(system) : undefined}
          linkTitle={controlsAvailable ? "Start" : "Locked"}
        />
      </Column>
      <Column medium={4}>
        <Card
          className="requirements-card"
          title="Requirements"
          linkClassName={requirementsAvailable ? "unlocked" : "locked"}
          linkTo={requirementsAvailable ? requirementsUrl(system) : undefined}
          linkTitle={requirementsAvailable ? "Start" : "Locked"}
        />
      </Column>
    </Row>
  );

  let secondRow = (
    <Row>
      <Column medium={4}>
        <Card
          className="threats-card"
          title="Threats"
          linkClassName={threatsAvailable ? "unlocked" : "locked"}
          linkTo={threatsAvailable ? threatsUrl(system) : undefined}
          linkTitle={threatsAvailable ? "Start" : "Locked"}
        />
      </Column>
      <Column medium={4}>
        <Card
          className="capabilities-card"
          title="CMMC Practices"
          linkClassName={"unlocked"}
          linkTo={capabilitiesUrl(system)}
          linkTitle={"Start"}
        />
      </Column>
      <Column medium={4}>
        <Card
          className="action-plan-card"
          title="Action Plan"
          linkClassName={actionPlanAvailable ? "unlocked" : "locked"}
          linkTo={actionPlanAvailable ? actionPlanUrl(system) : undefined}
          linkTitle={actionPlanAvailable ? "Start" : "Locked"}
        />
      </Column>
    </Row>
  );

  let certificationCard;
  if (canEditProcesses) {
    certificationCard = (
      <Card
        className="certifier-card"
        title="Certifier"
        linkClassName={certificationAvailable ? "unlocked" : "locked"}
        linkTo={certificationAvailable ? certificationUrl(system) : undefined}
        linkTitle={certificationAvailable ? "Start" : "Locked"}
      />
    );
  }

  if (!showCmmcModules) {
    secondRow = (
      <Row>
        <Column medium={4}>
          <Card
            className="threats-card"
            title="Threats"
            linkClassName={threatsAvailable ? "unlocked" : "locked"}
            linkTo={threatsAvailable ? threatsUrl(system) : undefined}
            linkTitle={threatsAvailable ? "Start" : "Locked"}
          />
        </Column>
        <Column medium={4}>
          <Card
            className="action-plan-card"
            title="Action Plan"
            linkClassName={actionPlanAvailable ? "unlocked" : "locked"}
            linkTo={actionPlanAvailable ? actionPlanUrl(system) : undefined}
            linkTitle={actionPlanAvailable ? "Start" : "Locked"}
          />
        </Column>
        <Column medium={4}>
          <Card
            className="reports-card"
            title="Reports"
            linkClassName={reportsAvailable ? "unlocked" : "locked"}
            linkTo={reportsAvailable ? reportsUrl(system) : undefined}
            linkTitle={reportsAvailable ? "Start" : "Locked"}
          />
        </Column>
      </Row>
    );
  }

  let thirdRow = (
    <Row>
      <Column medium={4}>
        <Card
          className="reports-card"
          title="Reports"
          linkClassName={reportsAvailable ? "unlocked" : "locked"}
          linkTo={reportsAvailable ? reportsUrl(system) : undefined}
          linkTitle={reportsAvailable ? "Start" : "Locked"}
        />
      </Column>
      <Column medium={4}>
        {certificationCard}
      </Column>
      <Column medium={4} />
    </Row>
  );

  if (!showCmmcModules) {
    thirdRow = (
      <Row />
    );
  }

  let pageContent = (
    <Section>
      <SystemSummary system={system} />
      {firstRow}
      {secondRow}
      {thirdRow}
    </Section>
  );

  if (system.status === 'trial_expired') {
    pageContent = <ExpiredTrialNotification system={system} />;
  };

  return (
    <div id="system-page" className="portal">
      {pageContent}
    </div>
  );
};

export default SystemPage;
