import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import M from 'materialize-css';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import LogoutButton from '../User/LogoutButton';
import Breadcrumbs from './Breadcrumbs';
import { SideNavButton } from '../../lib/layout/SideNav';
import { useDropdownEffect } from '../lib/use-dropdown-effect';

import { selectUser } from '../../lib/userSlice';

const HeaderWithSidebar = ({ children }) => {
  const user = useSelector(selectUser);
  const [circleClass, setCircleClass] = useState('hide-on-med-and-down');
  useDropdownEffect();
  const loadSideNav = () => {
    const elems = document.querySelectorAll('.sidenav');
    const instance = M.Sidenav.getInstance(elems[0]);
  };

  useEffect(() => {
    loadSideNav();
  }, []);

  const updateCircleClass = (e) => {
    e.preventDefault();
    let newClass = '';
    if (circleClass === '') {
      newClass = 'hide-on-med-and-down';
    }
    setCircleClass(newClass);
  };

  return (
    <header>
      <ul id="account-dropdown" className="dropdown-content">
        <li><Link to="/account/edit">Update Account</Link></li>
        <li className="divider"></li>
        <li><LogoutButton /></li>
      </ul>

      <nav className="top-nav">
        <div className="nav-wrapper">
          <Row>
            <Column small={1} className="hide-on-large-only">
              <div className="container">
                <SideNavButton
                  sideNavId="nav-mobile"
                  className="top-nav full"
                  onClick={updateCircleClass}
                />
              </div>
            </Column>
            <Column small={1}>
              <ul className="left">
                <Link to="/">ASCERTIS</Link>
              </ul>
            </Column>
            <Column small={4} />
            <Column small={6}>
              <ul className="right">
                <li>
                  <a className="dropdown-trigger" href="#" data-target="account-dropdown">
                    Account<i className="material-icons right">arrow_drop_down</i>
                  </a>
                </li>
              </ul>
            </Column>
          </Row>
        </div>
      </nav>
      <Breadcrumbs />
      <div id="sidebar" className={circleClass}>
        {children}
      </div>
    </header>
  );
};

HeaderWithSidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

HeaderWithSidebar.defaultProps = {
  children: null
};

export default HeaderWithSidebar;
