import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

class Preloader extends React.Component {
  componentDidMount() {
    // Include any Preloader jQuery initialization (Materialize)
  }

  render() {
    const { className } = this.props;

    const preloaderClasses = classNames({
      'preloader-wrapper': true,
      big: true,
      active: true,
      [`${className}`]: className
    });

    return (
      <div className={preloaderClasses}>
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>

          <div className="gap-patch">
            <div className="circle" />
          </div>

          <div className="circle-clipper right">
            <div className="circle" />
          </div>
        </div>
      </div>
    );
  }
}

Preloader.propTypes = {
  className: PropTypes.string,
};

Preloader.defaultProps = {
  className: ''
};

export default Preloader;
