import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Row from '../../lib/layout/Row';
import Section from '../../lib/layout/Section';
import Preloader from '../../lib/layout/Preloader';
import Pagination from '../../lib/widgets/Pagination';
import ActionPlanUnavailable from './ActionPlanUnavailable';
import ActionItemList from './ActionItemList';
import ActionItemsDownloadLink from './ActionItemsDownloadLink';
import ActionItemFilter from './ActionItemFilter';

import {
  useGetSystemQuery,
  useGetActionPlanQuery,
  useGetActionItemsQuery,
  useGetSystemNotesQuery
} from '../../lib/ascertis-api';

const hasFilter = (system) => _.includes(system.availableFeatures, 'poam_filtering');

const ActionPlanPage = () => {
  const history = useHistory();
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: actionPlan } = useGetActionPlanQuery({ systemId });
  const { data: actionItems } = useGetActionItemsQuery({ systemId });
  const { data: notes } = useGetSystemNotesQuery({ systemId });

  const allStates = ['missing_data', 'pending', 'complete', 'past_due'];
  const [sortColumn, setSortColumn] = useState('controlOrderNumber');
  const [visibleStates, setvisibleStates] = useState(allStates);
  const [pageNumber, setPageNumber] = useState(1);

  if (!system || !actionPlan || !actionItems || !notes) {
    return <Preloader />;
  } else if (actionItems.length === 0) {
    return <ActionPlanUnavailable />;
  }

  const visibleItems = _.filter(actionItems, (item) => {
    return _.includes(visibleStates, item.remediationStatus);
  });

  const sortedItems = _.sortBy(visibleItems, (item) => {
    return item[sortColumn];
  });

  const handleFilter = (values) => {
    setSortColumn(values.sortColumn);
    if (values.visibleStates.length === 0) {
      setvisibleStates(allStates);
    } else {
      setvisibleStates(values.visibleStates);
    }
  };

  let title = 'POA&M (Loading...)';
  let pagedGroups = [];
  let itemPages = [];
  if (sortedItems.length > 0) {
    title = 'POA&M';

    const groupedItems = _.groupBy(sortedItems, 'controlIdNumber');
    itemPages = _.chunk(_.keys(groupedItems), 10);
    pagedGroups = _.pick(groupedItems, itemPages[pageNumber - 1]);
  }

  let filterArea = undefined;
  if (hasFilter(system, 'poam_filtering')) {
    filterArea = <ActionItemFilter onSelect={handleFilter} />;
  }

  const downloadItems = _.map(sortedItems, (item) => {
    const controlNotes = _.filter(notes, (note) => {
      return (note.noteScope === 'control') && (note.notableId === item.controlNumber);
    });

    const requirementNotes = _.filter(notes, (note) => {
      return (note.noteScope === 'requirement') && _.includes(note.requirements, item.requirementNumber);
    });

    const controlNotesList = _.map(controlNotes, (note) => note.body);
    const requirementNotesList = _.map(requirementNotes, (note) => note.body);

    return {
      ...item,
      controlNotes: _.join(controlNotesList, '\n'),
      requirementNotes: _.join(requirementNotesList, '\n')
    }
  });

  const handleUploadClick = () => {
    history.push(`/systems/${system.id}/action_plan/upload`);
  };

  const linkArea = (
    <div className="action-plan-link-area">
      <Row>
        <Column small={12}>
          <div className="left">
            <a className="btn upload-link" onClick={handleUploadClick}>
              Upload POA&M Items
            </a>
          </div>
          <div className="right">
            <ActionItemsDownloadLink actionItems={downloadItems} />
          </div>
        </Column>
      </Row>
    </div>
  );

  return (
    <div id="action_plan" className="app-container">
      <main>
        <Page>
          <Row>
            <Column small={1} />
            <Column small={10}>
              <Section>
                <h5>{title}</h5>
              </Section>
              {linkArea}
              {filterArea}
              <Pagination
                numberOfPages={itemPages.length}
                currentPageNumber={pageNumber}
                onClick={setPageNumber}
              />
              <div className="action-item-list">
                <ActionItemList
                  system={system}
                  actionItems={pagedGroups}
                  notes={notes}
                />
              </div>
              <Pagination
                numberOfPages={itemPages.length}
                currentPageNumber={pageNumber}
                onClick={setPageNumber}
              />
            </Column>
            <Column small={1} />
          </Row>
        </Page>
      </main>
    </div>
  );
};

export default ActionPlanPage;
