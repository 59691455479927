// This was the useful Dropzone tutorial that I read
// https://react-dropzone.netlify.com/
// And if we want to incorporate a way to do this in React Native, this looks
// like the right resource
// https://github.com/SheetJS/js-xlsx/tree/master/demos/react

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const FileDragDropUploader = ({ onUpload, ...props }) => {
  const onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        // do whatever you want with the file content
        const fileAsBinaryString = reader.result;
        onUpload(fileAsBinaryString, file);
      };
      // Error handling
      reader.onabort = () => { return console.log('file reading was aborted'); };
      reader.onerror = () => { return console.log('file reading has failed'); };
      reader.readAsBinaryString(file);
    });
  };

  return (
    <div className="file-drag-drop-upload">
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className="dropzone" >
              <input id="drag-drop-uploader" {...getInputProps()} />
              {
                isDragActive ? <div className="btn">Drop files here...</div> : <div className="btn">Click to select files to upload.</div>
              }
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

FileDragDropUploader.propTypes = {
  onUpload: PropTypes.func,
};

FileDragDropUploader.defaultProps = {
  onUpload: () => {}
};

export default FileDragDropUploader;
