import React from 'react';
import PropTypes from 'prop-types';

import ProgressCircle from '../../lib/layout/ProgressCircle';
import { SideNav } from '../../lib/layout/SideNav';

const ProgressSidenav = ({ percentage, children }) => {
  return (
    <div id="progress-sidenav">
      <SideNav id="nav-mobile" fixed >
        <div className="progress-circle-container">
          <ProgressCircle value={parseFloat(percentage)} description="Completion %" />
        </div>
        { children }
      </SideNav>
    </div>
  );
};

ProgressSidenav.propTypes = {
  percentage: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ProgressSidenav.defaultProps = {
  system: undefined,
  children: null
};

export default ProgressSidenav;
