import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';
const moment = require('moment');

import { useSignOutMutation } from '../../../lib/ascertis-api';
import { removeUser } from '../../../lib/userSlice';
import { setSessionEnd, getSessionEnd } from '../../../utils/jwt-helpers';

export const useLogoutOnClose = () => {
  const [logoutUser] = useSignOutMutation();
  const dispatch = useDispatch();
  const bufferSeconds = 5;

  // set a temp expiration time when we reload/close the tab.  If we re-enable
  // the app within 5 seconds, then it's effectively just a page refresh
  useBeforeunload(() => {
    setSessionEnd();
  });

  const expireUserIfCacheExpired = () => {
    // This checks to ensure that we log the user out on sessions that ended more than 5 seconds
    // ago, but allows for short term page refreshing.
    const lastSessionEnd = getSessionEnd();

    if (lastSessionEnd) {
      const expired = moment(lastSessionEnd).add(bufferSeconds, 'seconds') < moment();
      if (expired) {
        void logoutUser();
        dispatch(removeUser());
      }
    }
  };

  useEffect(() => {
    // Add a 1 second delay, just to give the component time to finish rendering
    setTimeout(() => {
      expireUserIfCacheExpired();
    }, 1000);
  }, []);
}
